import React from "react";
import { Spinner } from "reactstrap";
import classNames from "helpers/classNames";
import styles from "./Preloader.module.scss";

const cx = classNames(styles);

const Preloader: React.FC<PreloaderProps> = () => {
    return (
        <div
            className={cx(
                "py-3",
                "align-items-center",
                "d-flex",
                "justify-content-center"
            )}
        >
            <Spinner className={cx("mr-2")} />
            Ładowanie...
        </div>
    );
};

export interface PreloaderProps {}

export default Preloader;
