import React from "react";
import classNames from "helpers/classNames";
import getFileUrl from "helpers/getFileUrl";
import styles from "./Thumbnail.module.scss";

const cx = classNames(styles);

const Thumbnail: React.FC<ThumbnailProps> = ({ image, className }) => {
    if (!image) {
        return <></>;
    }
    return (
        <div
            className={cx(
                className || "",
                "thumbnail",
                "embed-responsive",
                "embed-responsive-16by9"
            )}
            style={{
                backgroundImage: `url("${getFileUrl(image)}")`,
            }}
        />
    );
};

export interface ThumbnailProps {
    image: string;
    className?: string;
}

export default Thumbnail;
