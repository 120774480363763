import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactPaginate from "react-paginate";
import classNames from "helpers/classNames";
import styles from "./Pagination.module.scss";
import { useMedia } from "react-use";

const cx = classNames(styles);

const Pagination: React.FC<PaginationProps> = ({
    className,
    page = 1,
    totalPages = 1,
    onChange,
}) => {
    const pageRange = useMedia("(min-width: 768px") ? 5 : 2;
    if (totalPages <= 1) {
        return <></>;
    }
    return (
        <div className={cx("d-flex", "justify-content-center")}>
            <ReactPaginate
                marginPagesDisplayed={2}
                pageRangeDisplayed={pageRange}
                pageCount={totalPages}
                forcePage={page - 1}
                initialPage={page - 1}
                onPageChange={({ selected }) => onChange(selected + 1)}
                disableInitialCallback
                containerClassName={cx(className, "pagination")}
                breakLinkClassName={cx("page-link")}
                breakClassName={cx("page-item")}
                pageClassName={cx("page-item")}
                previousClassName={cx("page-item")}
                nextClassName={cx("page-item")}
                pageLinkClassName={cx("page-link")}
                previousLinkClassName={cx("page-link")}
                nextLinkClassName={cx("page-link")}
                activeClassName={cx("active")}
                previousLabel={
                    <>
                        <FontAwesomeIcon
                            className={cx("mr-md-2")}
                            icon={faChevronLeft}
                        />
                        <span className={cx("d-none", "d-md-inline")}>
                            Poprzednia
                        </span>
                    </>
                }
                nextLabel={
                    <>
                        <span className={cx("d-none", "d-md-inline")}>
                            Następna
                        </span>
                        <FontAwesomeIcon
                            className={cx("ml-md-2")}
                            icon={faChevronRight}
                        />
                    </>
                }
            />
        </div>
    );
};

export interface PaginationProps {
    className?: string;
    page: number;
    totalPages: number;
    onChange: (newPage: number) => void | any;
}

export default Pagination;
