import { Col, Container, Row } from "reactstrap";
import React, { useState } from "react";
import {
    faCheckCircle,
    faEye,
    faPlus,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import Button from "components/elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormularzDodawaniaKlienta from "./components/FormularzDodawaniaKlienta";
import { Link } from "react-router-dom";
import Pagination from "components/elements/Pagination";
import Preloader from "components/elements/Preloader";
import ViewTitle from "components/elements/ViewTitle";
import classNames from "helpers/classNames";
import styles from "./KlienciApiView.module.scss";
import useKlienciApi from "api/hooks/useKlienciApi";
import useQueryString from "hooks/useQueryString";

const cx = classNames(styles);

const KlienciApiView: React.FC<KlienciApiViewProps> = () => {
    const [query, updateQuery] = useQueryString<{ strona?: string }>();
    const klienci = useKlienciApi({
        strona: parseInt(query.strona || "1") || 1,
        na_stronie: 20,
    });
    const [pokazFormularzDodawania, setPokazFormularzDodawania] = useState(!1);
    return (
        <Container className={cx("mt-5")}>
            <Row className={cx("align-items-center")}>
                <Col xs="12" md className={cx("mb-3")}>
                    <ViewTitle>Klienci API</ViewTitle>
                </Col>
                <Col xs="12" md="auto" className={cx("mb-3")}>
                    <Button
                        color="secondary"
                        className={cx("w-100")}
                        onClick={() => setPokazFormularzDodawania(true)}
                        label="Nowy klient"
                        icon={faPlus}
                    />
                </Col>
            </Row>
            {klienci.status === "loading" && <Preloader />}
            {klienci.status === "success" && (
                <>
                    {!klienci.data.data.length && <p>Brak wyników.</p>}
                    {klienci.data.data.map((klient) => (
                        <div
                            key={klient.guid}
                            className={cx(
                                "border",
                                "rounded",
                                "shadow",
                                "px-3",
                                "py-2",
                                "mb-3"
                            )}
                        >
                            <Row className={cx("align-items-center")}>
                                <Col xs className={cx("font-weight-bold")}>
                                    {klient.nazwa}
                                </Col>
                                <Col xs="auto">
                                    <Link
                                        className={cx(
                                            "btn",
                                            "btn-outline-secondary",
                                            "btn-sm"
                                        )}
                                        to={`/schematyzm-api/klienci/${klient.guid}`}
                                    >
                                        <FontAwesomeIcon
                                            className={cx("mr-2")}
                                            icon={faEye}
                                        />
                                        Szczegóły
                                    </Link>
                                </Col>
                                <Col xs="12" className={cx("my-1")} />
                                <Col
                                    xs="auto"
                                    className={cx({
                                        "text-success": klient.czy_aktywny,
                                        "text-danger": !klient.czy_aktywny,
                                    })}
                                >
                                    <FontAwesomeIcon
                                        className={cx("mr-2")}
                                        icon={
                                            klient.czy_aktywny
                                                ? faCheckCircle
                                                : faTimesCircle
                                        }
                                    />
                                    {klient.czy_aktywny
                                        ? "Aktywny"
                                        : "Nieaktywny"}
                                </Col>
                                <Col xs>
                                    Klucz:&nbsp;
                                    <code>{klient.klucz_api}</code>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    <Pagination
                        className={cx("mt-3")}
                        page={klienci.data.meta.current_page}
                        totalPages={klienci.data.meta.last_page}
                        onChange={(nowaStrona) => {
                            updateQuery({
                                ...query,
                                strona: nowaStrona.toString(),
                            });
                            window.scrollTo(0, 0);
                        }}
                    />
                </>
            )}
            <FormularzDodawaniaKlienta
                isOpen={pokazFormularzDodawania}
                onClose={() => setPokazFormularzDodawania(false)}
            />
        </Container>
    );
};

export interface KlienciApiViewProps {}

export default KlienciApiView;
