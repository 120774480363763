import {
    Button,
    Container,
    FormFeedback,
    Input,
    Label,
    Spinner,
} from "reactstrap";
import React, { useState } from "react";

import classNames from "helpers/classNames";
import styles from "./ArtisanView.module.scss";
import useApiCallback from "api/hooks/useApiCallback";

const cx = classNames(styles);

const ArtisanView: React.FC<ArtisanViewProps> = () => {
    const [command, setCommand] = useState("");
    const [wykonaj, metaWykonania] = useApiCallback<ArtisanCallResponse>(
        "/artisan/",
        {
            method: "POST",
        }
    );

    return (
        <Container className={cx("pt-5", "pb-3")}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    wykonaj({ command });
                }}
            >
                <Label>Komenda</Label>
                <Input
                    name="command"
                    defaultValue=""
                    onChange={(e) => setCommand(e.target.value)}
                />
                <Button className={cx("mt-2")} type="submit">
                    Wykonaj
                </Button>
            </form>
            {metaWykonania.status === "loading" && (
                <Spinner className={cx("mt-3")} />
            )}
            {!!metaWykonania.data && (
                <>
                    <Label className={cx("mt-3")}>Wynik</Label>
                    <pre className={cx("w-100", "border", "p-3")}>
                        {metaWykonania.data}
                    </pre>
                </>
            )}
            {!!metaWykonania.error && (
                <FormFeedback className={cx("d-block", "mt-3")} valid={false}>
                    Błąd: {metaWykonania.error.message}
                </FormFeedback>
            )}
        </Container>
    );
};

type ArtisanCallResponse = string;

interface IRequest {
    command: string;
}

export interface ArtisanViewProps {}

export default ArtisanView;
