import { Col, Container, Row } from "reactstrap";
import React, { useState } from "react";
import {
    faCheckCircle,
    faPencilAlt,
    faTimesCircle,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import Button from "components/elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormularzEdycjiKlientaApi from "./components/FormularzEdycjiKlientaApi";
import ModalUsuwaniaKlientaApi from "./components/ModalUsuwaniaKlientaApi";
import Preloader from "components/elements/Preloader";
import ViewTitle from "components/elements/ViewTitle";
import classNames from "helpers/classNames";
import styles from "./KlientApiDetailsView.module.scss";
import useKlientApiDetails from "api/hooks/useKlientApiDetails";
import { useParams } from "react-router-dom";

const cx = classNames(styles);

const KlientApiDetailsView: React.FC<KlientApiDetailsViewProps> = () => {
    const [pokazFormularzEdycji, setPokazFormularzEdycji] = useState(false);
    const [pokazModalUsuwania, setPokazModalUsuwania] = useState(false);
    const { guid } = useParams<{ guid: string }>();
    const klient = useKlientApiDetails(guid);

    return (
        <Container className={cx("mt-5")}>
            {klient.status === "loading" && <Preloader />}
            {klient.status === "success" && (
                <Row form>
                    <Col xs="12" md className={cx("mb-3")}>
                        <ViewTitle>{klient.data.data.nazwa}</ViewTitle>
                        <small className={cx("font-weight-bold", "text-muted")}>
                            <span>
                                Utworzono: {klient.data.data.czas_utworzenia}
                            </span>
                            {klient.data.data.czas_utworzenia !==
                                klient.data.data.czas_aktualizacji && (
                                <span className={cx("ml-2")}>
                                    Aktualizacja:{" "}
                                    {klient.data.data.czas_aktualizacji}
                                </span>
                            )}
                        </small>
                    </Col>
                    <Col xs="12" md="auto" className={cx("mb-3")}>
                        <Button
                            color="danger"
                            className={cx("w-100")}
                            onClick={() => setPokazModalUsuwania(true)}
                            label="Usuń"
                            icon={faTrashAlt}
                        />
                    </Col>
                    <Col xs="12" md="auto" className={cx("mb-3")}>
                        <Button
                            color="secondary"
                            className={cx("w-100")}
                            onClick={() => setPokazFormularzEdycji(true)}
                            icon={faPencilAlt}
                            label="Edytuj"
                        />
                    </Col>
                    <Col xs="12" className={cx("my-1")} />
                    <Col
                        xs="auto"
                        className={cx({
                            "text-success": klient.data.data.czy_aktywny,
                            "text-danger": !klient.data.data.czy_aktywny,
                        })}
                    >
                        <FontAwesomeIcon
                            className={cx("mr-2")}
                            icon={
                                klient.data.data.czy_aktywny
                                    ? faCheckCircle
                                    : faTimesCircle
                            }
                        />
                        {klient.data.data.czy_aktywny
                            ? "Aktywny"
                            : "Nieaktywny"}
                    </Col>
                    <Col xs>
                        Klucz:&nbsp;
                        <code>{klient.data.data.klucz_api}</code>
                    </Col>
                    <FormularzEdycjiKlientaApi
                        klient={klient.data.data}
                        isOpen={pokazFormularzEdycji}
                        onClose={() => setPokazFormularzEdycji(false)}
                    />
                    <ModalUsuwaniaKlientaApi
                        klient={klient.data.data}
                        isOpen={pokazModalUsuwania}
                        onClose={() => setPokazModalUsuwania(false)}
                    />
                </Row>
            )}
        </Container>
    );
};

export interface KlientApiDetailsViewProps {}

export default KlientApiDetailsView;
