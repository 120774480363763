import { Badge, Col, Container, Row } from "reactstrap";
import React, { useState } from "react";
import {
    faCheckCircle,
    faTimesCircle,
    faLink,
    faMapMarkerAlt,
    faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";

import Button from "components/elements/Button";
import EdycjaParafiiModal from "./components/EdycjaParafiiModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LabeledInfo from "components/elements/LabeledInfo";
import Preloader from "components/elements/Preloader";
import classNames from "helpers/classNames";
import getFileUrl from "helpers/getFileUrl";
import styles from "./ParafiaDetailsView.module.scss";
import useCzyMozeEdytowacDiecezje from "hooks/useCzyMozeEdytowacDiecezje";
import useParafiaDetails from "api/hooks/useParafiaDetails";
import { useParams } from "react-router-dom";

const cx = classNames(styles);

const ParafiaDetailsView: React.FC<ParafiaDetailsViewProps> = () => {
    const { guid } = useParams<IViewParams>();
    const parafia = useParafiaDetails(guid, {
        enabled: !!guid,
    });
    const [pokazFormularzEdycji, setPokazFormularzEdycji] = useState(false);
    const mozeEdytowac = useCzyMozeEdytowacDiecezje(
        parafia.data ? parafia.data.data.diecezja_id : null
    );

    if (parafia.status === "loading") {
        return (
            <Container className={cx("pt-5")}>
                <Preloader />
            </Container>
        );
    }

    const openInGeoportal = () => {
        if (!parafia.data || !parafia.data.data.adres_uniwersalny) {
            return;
        }

        const adres = parafia.data.data.adres_uniwersalny.split("|");
        const y = adres[5];
        const x = adres[6];
        if (!y || !x) {
            return;
        }

        const url = `https://mapy.geoportal.gov.pl/imap/Imgp_2.html?actions=acAddPoint@x=${x};y=${y};epsg=2180`;
        window.open(url, "_blank");
    };

    if (!parafia.data) {
        return null;
    }

    const data = parafia.data.data;

    return (
        <Container className={cx("pt-5")}>
            <h3 className={cx("h4", "font-weight-bold", "mb-3")}>
                {data.nazwa}
            </h3>
            {!!mozeEdytowac && (
                <Row className={cx("justify-content-end", "mb-3")}>
                    <Col xs="12" md="auto">
                        <Button
                            type="button"
                            className={cx("w-100")}
                            onClick={() => setPokazFormularzEdycji(true)}
                            icon={faPencilAlt}
                            label="Edycja"
                            color="secondary"
                        />
                    </Col>
                </Row>
            )}
            <LabeledInfo label="Nazwa">{data.nazwa}</LabeledInfo>
            <LabeledInfo label="Nazwa skrócona">
                {data.nazwa_skrocona}
            </LabeledInfo>
            <LabeledInfo label="Nazwa historyczna">
                {data.nazwa_historyczna}
            </LabeledInfo>
            <LabeledInfo label="Nazwa zwyczajowa">
                {data.nazwa_zwyczajowa}
            </LabeledInfo>
            <LabeledInfo
                label="Adres uniwersalny"
                className={cx("adresUniwersalny")}
            >
                {!!data.adres_uniwersalny &&
                    data.adres_uniwersalny
                        .split("|")
                        .filter((i) => !!i)
                        .map((part, index) => (
                            <React.Fragment key={index}>
                                <span>{part}</span>
                                <span>|</span>
                            </React.Fragment>
                        ))}
            </LabeledInfo>

            <LabeledInfo label="Czy synchronizować adres z bazą TERYT?">
                {!!data.czy_synchronizowac_teryt ? (
                    <Badge
                        color="success"
                        className={cx("p-2", "font-weight-normal")}
                    >
                        <FontAwesomeIcon
                            className={cx("mr-2")}
                            icon={faCheckCircle}
                        />
                        <span className={cx("badgeLabel")}>Tak</span>
                    </Badge>
                ) : (
                    <Badge
                        color="danger"
                        className={cx("p-2", "font-weight-normal")}
                    >
                        <FontAwesomeIcon
                            className={cx("mr-2")}
                            icon={faTimesCircle}
                        />
                        <span className={cx("badgeLabel")}>Nie</span>
                    </Badge>
                )}
            </LabeledInfo>

            <LabeledInfo label="Adres">{data.adres}</LabeledInfo>
            <LabeledInfo label="Gmina">{data.gmina}</LabeledInfo>
            <LabeledInfo label="Powiat">{data.powiat}</LabeledInfo>
            <LabeledInfo label="Województwo">{data.wojewodztwo}</LabeledInfo>

            {!!mozeEdytowac && (
                <>
                    <LabeledInfo label="Adres e-mail">
                        {data.email || null}
                        {!!data.email &&
                            (!!data.czy_email_zweryfikowany ? (
                                <Badge
                                    color="success"
                                    className={cx("ml-2", "font-weight-normal")}
                                >
                                    <FontAwesomeIcon
                                        className={cx("mr-2")}
                                        icon={faCheckCircle}
                                    />
                                    zweryfikowany
                                </Badge>
                            ) : (
                                <Badge
                                    color="danger"
                                    className={cx("ml-2", "font-weight-normal")}
                                >
                                    <FontAwesomeIcon
                                        className={cx("mr-2")}
                                        icon={faTimesCircle}
                                    />
                                    niezweryfikowany
                                </Badge>
                            ))}
                    </LabeledInfo>
                    <LabeledInfo label="Nr telefonu">
                        {data.telefon || null}
                        {!!data.telefon &&
                            (!!data.czy_telefon_zweryfikowany ? (
                                <Badge
                                    color="success"
                                    className={cx("ml-2", "font-weight-normal")}
                                >
                                    <FontAwesomeIcon
                                        className={cx("mr-2")}
                                        icon={faCheckCircle}
                                    />
                                    zweryfikowany
                                </Badge>
                            ) : (
                                <Badge
                                    color="danger"
                                    className={cx("ml-2", "font-weight-normal")}
                                >
                                    <FontAwesomeIcon
                                        className={cx("mr-2")}
                                        icon={faTimesCircle}
                                    />
                                    niezweryfikowany
                                </Badge>
                            ))}
                    </LabeledInfo>
                </>
            )}

            {!!data.adres_uniwersalny && (
                <LabeledInfo label="Lokalizacja parafii na mapie GEOPORTALU">
                    <Button
                        type="button"
                        size="sm"
                        className={cx("py-1")}
                        icon={faMapMarkerAlt}
                        label="Zobacz na mapie"
                        color="secondary"
                        onClick={() => openInGeoportal()}
                    />
                </LabeledInfo>
            )}

            {/*
            <LabeledInfo label="Strona internetowa">
                {data.strona_www}
            </LabeledInfo>
            <LabeledInfo label="Nr konta bankowego">
                {data.nr_konta}
            </LabeledInfo>
            <LabeledInfo label="NIP">{data.nip}</LabeledInfo>
            <LabeledInfo label="REGON">{data.regon}</LabeledInfo>
            <LabeledInfo label="Data erygowania">
                {data.data_erygowania}
            </LabeledInfo>
        */}

            {!!data.dokument_erygujacy && (
                <LabeledInfo label="Dokument erygujący">
                    <Button
                        type="button"
                        size="sm"
                        className={cx("my-1")}
                        icon={faLink}
                        label="Otwórz dokument"
                        color="primary"
                        onClick={() => {
                            const url = getFileUrl(
                                data.dokument_erygujacy || ""
                            );
                            window.open(url, "_blank");
                        }}
                    />
                </LabeledInfo>
            )}

            {/*
            <LabeledInfo label="Święto patronalne">
                {data.swieto_patronalne}
            </LabeledInfo>
            <LabeledInfo label="Biuro parafialne">
                {data.biuro_parafialne}
            </LabeledInfo>            
            <LabeledInfo label="Nota historyczna">
                {data.nota_historyczna}
            </LabeledInfo>
        */}

            {!!mozeEdytowac && (
                <LabeledInfo label="Uwagi">{data.uwagi}</LabeledInfo>
            )}

            <EdycjaParafiiModal
                isOpen={pokazFormularzEdycji}
                parafia={parafia.data.data}
                onClose={() => setPokazFormularzEdycji(false)}
            />
        </Container>
    );
};

export interface IViewParams {
    guid: string;
}
export interface ParafiaDetailsViewProps {}

export default ParafiaDetailsView;
