import { Col, Container, Row } from "reactstrap";
import React, { useCallback, useEffect, useState } from "react";
import News from "./components/News";
import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";
import Schematyzm from "./components/Schematyzm";
import SchematyzmFiltry from "./components/SchematyzmFiltry";
import _isEqual from "lodash/isEqual";
import classNames from "helpers/classNames";
import styles from "./StronaGlowna.module.scss";
import useQueryString from "hooks/useQueryString";
import CtaSection from "./components/CtaSection";

const cx = classNames(styles);

const StronaGlowna: React.FC<StronaGlownaProps> = () => {
    const [query, updateQuery] = useQueryString<ParafieFiltrParams>();
    const [wyszukiwanie, setWyszukiwanie] = useState<
        ParafieFiltrParams["wyszukiwanie"]
    >(query.wyszukiwanie || "");
    const [filtr, setFiltr] = useState<ParafieFiltrParams["filtr"]>(
        query.filtr || {}
    );

    useEffect(() => {
        if ((query.wyszukiwanie || "") !== wyszukiwanie) {
            setWyszukiwanie(query.wyszukiwanie || "");
        }
        if (!_isEqual(query.filtr || {}, filtr)) {
            setFiltr(query.filtr || {});
        }
    }, [query.wyszukiwanie, query.filtr, wyszukiwanie, filtr]);

    const handleWyszukiwanie = useCallback(
        (nowe?: string) => {
            if ((nowe || "") === wyszukiwanie) {
                return;
            }
            setWyszukiwanie(nowe || "");
            updateQuery({ ...query, wyszukiwanie: nowe || undefined });
        },
        [query, updateQuery, wyszukiwanie]
    );

    const handleFiltr = useCallback(
        (nowy?: ParafieFiltrParams["filtr"]) => {
            if (_isEqual(nowy || {}, filtr)) {
                return;
            }
            setFiltr(nowy || {});
            updateQuery({ ...query, filtr: nowy || undefined });
        },
        [query, updateQuery, filtr]
    );

    return (
        <>
            <CtaSection />

            <Container className={cx("pt-5")}>
                <Row>
                    <Col md={{ size: 8, order: 2 }} lg={{ size: 9, order: 2 }}>
                        <h3 className={cx("h4", "font-weight-bold", "mb-3")}>
                            Wyszukaj
                        </h3>
                        <SchematyzmFiltry
                            onWyszukiwanie={handleWyszukiwanie}
                            onFiltr={handleFiltr}
                        />
                        <Schematyzm wyszukiwanie={wyszukiwanie} filtr={filtr} />
                    </Col>
                    <Col md={{ size: 4, order: 1 }} lg={{ size: 3, order: 1 }}>
                        <h3
                            className={cx(
                                "h4",
                                "font-weight-bold",
                                "mb-3",
                                "mt-5",
                                "mt-md-0"
                            )}
                        >
                            Aktualności
                        </h3>
                        <News />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export interface StronaGlownaProps {}

export default StronaGlowna;
