import DiecezjaListItem from "../DiecezjaListItem";
import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";
import Preloader from "components/elements/Preloader";
import React from "react";
import classNames from "helpers/classNames";
import styles from "./Schematyzm.module.scss";
import useDiecezje from "api/hooks/useDiecezje";

const cx = classNames(styles);

const Schematyzm: React.FC<SchematyzmProps> = ({ wyszukiwanie, filtr }) => {
    const diecezje = useDiecezje({ wyszukiwanie, filtr });
    return (
        <div className={cx("shadow", "rounded", "rounded-lg")}>
            {diecezje.status === "loading" && <Preloader />}
            {diecezje.status === "success" && !diecezje.data.data.length && (
                <div className={cx("py-3", "rounded", "border", "text-center")}>
                    Brak wyników
                </div>
            )}
            {diecezje.status === "success" &&
                diecezje.data.data.map((diecezja) => (
                    <DiecezjaListItem
                        key={diecezja.guid}
                        diecezja={diecezja}
                        wyszukiwanie={wyszukiwanie}
                        filtr={filtr}
                    />
                ))}
        </div>
    );
};

export interface SchematyzmProps extends ParafieFiltrParams {}

export default Schematyzm;
