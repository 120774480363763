import useApi, { UseApiOptions } from "./useApi";

import Aktualnosc from "api/schemas/Aktualnosc";
import ListingApiResponse from "api/schemas/ListingApiResponse";

const useAktualnosci = (
    params: AktualosciListingParams = {},
    options?: UseApiOptions<AktualnosciApiResponse>
) => {
    const res = useApi<AktualnosciApiResponse>("/aktualnosci", {
        params,
        ...options,
    });

    return res;
};

export type AktualosciListingParams = {
    strona?: number;
    na_stronie?: number;
};

export type AktualnosciApiResponse = ListingApiResponse<Aktualnosc>;

export default useAktualnosci;
