import { IRequestParams, RequestMethod } from "api/executeRequest";
import { QueryOptions, useQuery } from "react-query";

function useApi<ResponseType>(
    url: string,
    options?: UseApiOptions<ResponseType>
) {
    const { params = {}, ...config } = options || {};
    const res = useQuery<ResponseType, [string, IRequestParams, RequestMethod]>(
        {
            queryKey: [url, params || {}, "GET"],
            config: config,
        }
    );
    return res;
}

export interface UseApiOptions<ResponseType = {}>
    extends QueryOptions<ResponseType> {
    params?: IRequestParams;
}

export default useApi;
