import {
    Form,
    FormFeedback,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import React, { FormEvent, useEffect } from "react";

import { AktualnoscDetailsApiResponse } from "api/hooks/useAktualnoscDetails";
import ApiError from "api/ApiError";
import Button from "components/elements/Button";
import FormField from "components/elements/FormField";
// import classNames from "helpers/classNames";
import pobierzWartoscPola from "helpers/pobierzWartoscPola";
import { queryCache } from "react-query";
// import styles from "./FormularzDodawaniaKlienta.module.scss";
import useApiCallback from "api/hooks/useApiCallback";
import { useHistory } from "react-router-dom";

// const cx = classNames(styles);

const FormularzDodawaniaKlienta: React.FC<FormularzDodawaniaKlientaProps> = ({
    isOpen,
    onClose,
}) => {
    const { push } = useHistory();
    const [zapisz, metadaneZapisu] = useApiCallback<
        AktualnoscDetailsApiResponse
    >("/klienci-api", {
        method: "POST",
    });

    const wysylkaFormularza = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        zapisz(
            {
                nazwa: pobierzWartoscPola(form, "nazwa"),
                czy_aktywny: !!pobierzWartoscPola(form, "czy_aktywny"),
            },
            {
                onSuccess: () => {
                    // wymuszenie przeładowania danych
                    queryCache.invalidateQueries("/klienci-api");
                },
            }
        );
    };

    // automatyczne przekierowanie na widok szczegółów nowego artykułu
    useEffect(() => {
        if (metadaneZapisu.status !== "success") {
            return;
        }

        if (!metadaneZapisu.data.data) {
            return;
        }

        push(`/klienci-api/${metadaneZapisu.data.data.guid}`);
    }, [metadaneZapisu.status, metadaneZapisu.data, push]);

    const bledyWalidacji =
        metadaneZapisu.error instanceof ApiError
            ? metadaneZapisu.error.validation || {}
            : {};
    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            backdrop={metadaneZapisu.status === "loading" ? "static" : true}
        >
            <ModalHeader>Nowy klient API</ModalHeader>
            <Form onSubmit={wysylkaFormularza}>
                <ModalBody>
                    <FormField
                        required
                        disabled={metadaneZapisu.status === "loading"}
                        name="nazwa"
                        label="Nazwa"
                        maxLength={500}
                        error={bledyWalidacji.nazwa}
                    />
                    <Label>Aktywny</Label>
                    <FormField
                        type="switch"
                        disabled={metadaneZapisu.status === "loading"}
                        name="czy_aktywny"
                        inlineLabel="Aktywnuj klienta"
                        helpText="Zaznacz to pole, aby aktywować klienta API i umożliwić mu wykonywanie zapytań."
                        error={bledyWalidacji.nazwa}
                    />

                    {!!metadaneZapisu.error && (
                        <FormFeedback valid={false}>
                            {metadaneZapisu.error.message}
                        </FormFeedback>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        outline
                        color="secondary"
                        disabled={metadaneZapisu.status === "loading"}
                        onClick={onClose}
                        label="Anuluj"
                    />
                    <Button
                        color="secondary"
                        type="submit"
                        label="Zapisz"
                        disabled={metadaneZapisu.status === "loading"}
                        loading={metadaneZapisu.status === "loading"}
                    />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export interface FormularzDodawaniaKlientaProps {
    isOpen: boolean;
    onClose: () => void | any;
}

export default FormularzDodawaniaKlienta;
