import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Button from "components/elements/Button";
import KlientApi from "api/schemas/KlientApi";
import React from "react";
// import classNames from "helpers/classNames";
import { queryCache } from "react-query";
// import styles from "./ModalUsuwaniaKlientaApi.module.scss";
import useApiCallback from "api/hooks/useApiCallback";
import { useHistory } from "react-router-dom";

// const cx = classNames(styles);

const ModalUsuwaniaKlientaApi: React.FC<ModalUsuwaniaKlientaApiProps> = ({
    klient,
    isOpen,
    onClose,
}) => {
    const { push } = useHistory();
    const [
        usun,
        metadaneUsuwania,
    ] = useApiCallback(`/klienci-api/${klient.guid}`, { method: "DELETE" });

    const wysylkaFormularza = () => {
        usun(
            {},
            {
                onSuccess: () => {
                    // wymuszenie przeładowania danych listy i szczegółów
                    queryCache.invalidateQueries("/klienci-api");
                    // zamknięcie wyskakującego okna
                    onClose();
                    // przeniesienie na widok listy
                    push("/schematyzm-api/klienci");
                },
            }
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            backdrop={metadaneUsuwania.status === "loading" ? "static" : true}
        >
            <ModalHeader>Usuwanie klienta</ModalHeader>
            <ModalBody>
                <p>Czy na pewno chcesz usunąć klienta "{klient.nazwa}"?</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    outline
                    disabled={metadaneUsuwania.status === "loading"}
                    onClick={onClose}
                    label="Anuluj"
                />
                <Button
                    onClick={() => wysylkaFormularza()}
                    label="Usuń"
                    color="danger"
                    disabled={metadaneUsuwania.status === "loading"}
                    loading={metadaneUsuwania.status === "loading"}
                />
            </ModalFooter>
        </Modal>
    );
};

export interface ModalUsuwaniaKlientaApiProps {
    isOpen: boolean;
    klient: KlientApi;
    onClose: () => void | any;
}

export default ModalUsuwaniaKlientaApi;
