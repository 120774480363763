import { Col, Container, Row } from "reactstrap";
import React, { useState } from "react";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Button from "components/elements/Button";
import FormularzEdycjiAktualnosci from "./components/FormularzEdycjiAktualnosci";
import ModalUsuwaniaAktualnosci from "./components/ModalUsuwaniaAktualnosci";
import MultilineText from "components/elements/MultilineText";
import Preloader from "components/elements/Preloader";
import Thumbnail from "components/elements/Thumbnail";
import { TypKontaUzytkownika } from "api/schemas/Uzytkownik";
import ViewTitle from "components/elements/ViewTitle";
import classNames from "helpers/classNames";
import styles from "./AktualnoscDetailsView.module.scss";
import useAktualnoscDetails from "api/hooks/useAktualnoscDetails";
import useAuth from "hooks/useAuth";
import { useParams } from "react-router-dom";

const cx = classNames(styles);

const AktualnoscDetailsView: React.FC<AktualnoscDetailsViewProps> = () => {
    const [pokazFormularzEdycji, setPokazFormularzEdycji] = useState(false);
    const [pokazModalUsuwania, setPokazModalUsuwania] = useState(false);
    const { user } = useAuth();
    const { guid } = useParams<{ guid: string }>();
    const artykul = useAktualnoscDetails(guid);

    const mozeEdytowac =
        user?.typ_konta === TypKontaUzytkownika.TYP_KONTA_ADMIN;
    return (
        <Container className={cx("mt-5")}>
            {artykul.status === "loading" && <Preloader />}
            {artykul.status === "success" && (
                <>
                    <Row className={cx("align-items-start")} form>
                        <Col xs="12" md className={cx("mb-3")}>
                            <ViewTitle>{artykul.data.data.tytul}</ViewTitle>
                            <small
                                className={cx("font-weight-bold", "text-muted")}
                            >
                                <span>
                                    Utworzono:{" "}
                                    {artykul.data.data.czas_utworzenia}
                                </span>
                                {artykul.data.data.czas_utworzenia !==
                                    artykul.data.data.czas_aktualizacji && (
                                    <span className={cx("ml-2")}>
                                        Aktualizacja:{" "}
                                        {artykul.data.data.czas_aktualizacji}
                                    </span>
                                )}
                            </small>
                        </Col>
                        {!!mozeEdytowac && (
                            <Col xs="12" md="auto" className={cx("mb-3")}>
                                <Button
                                    color="danger"
                                    className={cx("w-100")}
                                    onClick={() => setPokazModalUsuwania(true)}
                                    label="Usuń"
                                    icon={faTrashAlt}
                                />
                            </Col>
                        )}
                        {!!mozeEdytowac && (
                            <Col xs="12" md="auto" className={cx("mb-3")}>
                                <Button
                                    color="secondary"
                                    className={cx("w-100")}
                                    onClick={() =>
                                        setPokazFormularzEdycji(true)
                                    }
                                    icon={faPencilAlt}
                                    label="Edytuj"
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        {!!artykul.data.data.obrazek && (
                            <Col xs="12" md="6" lg="4">
                                <Thumbnail image={artykul.data.data.obrazek} />
                            </Col>
                        )}
                        {!!artykul.data.data.zajawka && (
                            <Col xs="12" md>
                                <p
                                    className={cx(
                                        "font-italic",
                                        "font-weight-bold"
                                    )}
                                >
                                    {artykul.data.data.zajawka}
                                </p>
                            </Col>
                        )}
                    </Row>
                    <MultilineText text={artykul.data.data.tresc} />
                    {!!mozeEdytowac && (
                        <FormularzEdycjiAktualnosci
                            artykul={artykul.data.data}
                            isOpen={pokazFormularzEdycji}
                            onClose={() => setPokazFormularzEdycji(false)}
                        />
                    )}
                    {!!mozeEdytowac && (
                        <ModalUsuwaniaAktualnosci
                            artykul={artykul.data.data}
                            isOpen={pokazModalUsuwania}
                            onClose={() => setPokazModalUsuwania(false)}
                        />
                    )}
                </>
            )}
        </Container>
    );
};

export interface AktualnoscDetailsViewProps {}

export default AktualnoscDetailsView;
