import { CustomInput, CustomInputProps, Input, InputProps } from "reactstrap";
import React, { useMemo } from "react";

// import classNames from "helpers/classNames";
// import styles from "./InputComponent.module.scss";

// const cx = classNames(styles);

const InputComponent: React.FC<InputComponentProps> = ({ type, ...props }) => {
    const id = useMemo(() => Math.random(), []);
    if (type === "switch" || type === "file" || type === "select") {
        return <CustomInput {...props} id={id} type={type} />;
    }

    return <Input type={type} {...props} />;
};

export interface InputComponentProps extends Omit<InputProps, "type" | "id"> {
    type?: InputProps["type"] | CustomInputProps["type"];
}

export default InputComponent;
