import useApi, { UseApiOptions } from "./useApi";

import ParafiaListItem from "api/schemas/ParafiaListItem";

const useParafiaDetails = (
    guid: string,
    options?: UseApiOptions<ParafiaDetailsApiResponse>
) => {
    const res = useApi<ParafiaDetailsApiResponse>(`/parafie/${guid}`, options);
    return res;
};

export type ParafiaDetailsApiResponse = {
    data: ParafiaListItem;
};

export default useParafiaDetails;
