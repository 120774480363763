class ApiError extends Error {
    status: number = 200;
    validation: IValidationErrors = {};

    constructor(
        status: number,
        message?: string,
        validationErrors?: IValidationErrors
    ) {
        super(message || `API Error: ${status}`);
        this.status = status;
        this.validation = validationErrors || {};
    }
}

export type IValidationErrors = {
    [key: string]: any;
};

export default ApiError;
