import React from "react";
import classNames from "helpers/classNames";
import styles from "./ViewTitle.module.scss";

const cx = classNames(styles);

const ViewTitle: React.FC<ViewTitleProps> = ({ children }) => {
    return <h1 className={cx("h4", "font-weight-bold")}>{children}</h1>;
};

export interface ViewTitleProps {}

export default ViewTitle;
