import {
    Button as BsButton,
    ButtonProps as BsButtonProps,
    Spinner,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import classNames from "helpers/classNames";
import styles from "./Button.module.scss";

const cx = classNames(styles);

const Button: React.FC<ButtonProps> = ({
    color = "primary",
    className,
    children,
    label,
    loading,
    icon,
    ...props
}) => {
    return (
        <BsButton {...props} className={cx(className, "button")} color={color}>
            {!!icon && (
                <FontAwesomeIcon
                    className={cx({
                        "mr-2": !!label || !!children,
                        invisible: !!loading,
                    })}
                    icon={icon}
                />
            )}
            <span className={cx("content")}>{label || children}</span>
            {!!loading && <Spinner className={cx("spinner")} />}
        </BsButton>
    );
};

export interface ButtonProps extends BsButtonProps {
    label?: string;
    loading?: boolean;
    icon?: IconProp;
}

export default Button;
