import { Card, CardBody, Col, Row } from "reactstrap";
import {
    faCheckCircle,
    faHome,
    faInfoCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import ApiParafiaListItem from "api/schemas/ParafiaListItem";
import Button from "components/elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";
import React from "react";
import classNames from "helpers/classNames";
import styles from "./ParafiaListItem.module.scss";
import { useHistory } from "react-router-dom";

const cx = classNames(styles);

const ParafiaListItem: React.FC<ParafiaListItemProps> = ({ parafia }) => {
    const { push } = useHistory();
    return (
        <Card className={cx("card", "bg-transparent")}>
            <CardBody className={cx("p-0")}>
                <Row
                    className={cx(
                        "align-items-center",
                        "pl-2",
                        "pl-lg-5",
                        "pr-2",
                        "pr-lg-3",
                        "py-3"
                    )}
                >
                    <Col xs="12" md={true}>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className={cx("mr-2", "mr-lg-3")}
                        />
                        {parafia.nazwa}
                    </Col>
                    <Col xs="6" md="auto" className={cx("py-2", "py-lg-0")}>
                        <Button
                            size="sm"
                            type="button"
                            className={cx("w-100")}
                            onClick={() => push(`/parafie/${parafia.guid}`)}
                            icon={faInfoCircle}
                            label="Szczegóły"
                        />
                    </Col>
                    {/* separator column below */}
                    <Col xs="12" className={cx("mb-2")} />
                    {!!parafia.adres && (
                        <>
                            <Col xs={true} className={cx("info")}>
                                <FontAwesomeIcon
                                    icon={faHome}
                                    className={cx("mr-2")}
                                />
                                {parafia.adres}
                            </Col>
                            <Col
                                xs="auto"
                                className={cx("py-2", "py-lg-0", "status")}
                            >
                                {!!parafia.czy_adres_zsynchronizowany ? (
                                    <>
                                        <FontAwesomeIcon
                                            className={cx("mr-2")}
                                            icon={faCheckCircle}
                                            color="#155724"
                                        />
                                        adres zsynchronizowany z TERYT
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon
                                            className={cx("mr-2")}
                                            icon={faTimesCircle}
                                            color="#721c24"
                                        />
                                        adres niezsynchronizowany z TERYT
                                    </>
                                )}
                            </Col>
                        </>
                    )}
                </Row>
            </CardBody>
        </Card>
    );
};

export interface ParafiaListItemProps extends ParafieFiltrParams {
    parafia: ApiParafiaListItem;
}

export default ParafiaListItem;
