import { Col, Row } from "reactstrap";

import { Link } from "react-router-dom";
import React from "react";
import Thumbnail from "components/elements/Thumbnail";
import classNames from "helpers/classNames";
import styles from "./NewsCard.module.scss";

const cx = classNames(styles);

const NewsCard: React.FC<NewsCardProps> = ({
    title,
    image,
    children,
    date,
    link,
}) => {
    return (
        <article className={cx("article", "p-3")}>
            {!!image && <Thumbnail className={cx("mb-2")} image={image} />}
            <h3 className={cx("font-weight-bold", "title")}>{title}</h3>
            <p className={cx("content")}>{children}</p>
            <Row className={cx("footer")}>
                <Col sm="auto">{date}</Col>
                <Col
                    className={cx("font-italic", "font-weight-bold")}
                    sm="auto"
                >
                    <Link to={link}>Czytaj dalej...</Link>
                </Col>
            </Row>
        </article>
    );
};

export interface NewsCardProps {
    title: string;
    date: string;
    link: string;
    image?: string;
}

export default NewsCard;
