import { Col, Container, Row } from "reactstrap";

import { Link } from "react-router-dom";
import React from "react";
import classNames from "helpers/classNames";
import baner from "static/baner.png";
import styles from "./Footer.module.scss";

const cx = classNames(styles);

const Footer: React.FC<FooterProps> = () => {
    return (
        <footer className={cx("footer")}>
            <div className={cx("bg-primary", "text-white", "py-5")}>
                <Container>
                    <Row>
                        <Col lg="4" className={cx("col")}>
                            <h5 className={cx("h4")}>O schematyzmie</h5>
                            <p>
                                Schematyzm to wykaz (lista) diecezji Kościoła
                                katolickiego w Polsce z podziałem na dekanaty i
                                przynależne do nich parafie wraz z adresami
                                korespondencyjnymi.
                            </p>
                            <p>
                                Przyjęto, że adresem parafii jest adres
                                korespondencyjny miejsca, w którym przechowywane
                                są księgi parafialne (adres urzędu parafialnego
                                / kancelarii).
                            </p>
                            <p>
                                Adresem parafii nie jest adres kościoła
                                parafialnego.
                            </p>
                            <p className={cx("text-right")}>
                                <Link to="/o-schematyzmie/">
                                    Czytaj więcej...
                                </Link>
                            </p>
                        </Col>
                        <Col lg="3" className={cx("col", "mt-4", "mt-lg-0")}>
                            <h5 className={cx("h4")}>Podziękowania</h5>
                            <div className={cx("mb-3")}>
                                Schematyzm jest dziełem, do powstania którego
                                przyczyniły się liczne osoby i instytucje.
                                Serdeczne Bóg zapłać!
                            </div>
                            <p className={cx("text-right")}>
                                <Link to="/podziekowania/">
                                    Czytaj więcej...
                                </Link>
                            </p>
                        </Col>
                        <Col lg="3" className={cx("col", "mt-4", "mt-lg-0")}>
                            <h5 className={cx("h4")}>Patronat</h5>
                            -------------
                            <br />
                            <br />
                            <h6 className={cx("h6")}>Wsparcie modlitewne</h6>
                            <div>
                                <a
                                    href="https://www.faustyna.pl/zmbm/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Zgromadzenie Sióstr Matki Bożej Miłosierdzia
                                </a>
                            </div>
                            <h6 className={cx("h6", "mt-3")}>
                                Wsparcie techniczne
                            </h6>
                            <div>
                                <a
                                    href="https://indicoplus.pl"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Indico Plus sp. z o.o.
                                </a>
                            </div>
                        </Col>
                        <Col lg="2" className={cx("mt-5", "mt-lg-0")}>
                            <h5 className={cx("h4")}>Kontakt</h5>
                            <Link to="/kontakt" className={cx("text-white")}>
                                Skontaktuj się z nami
                            </Link>
                            <br />
                            <br />
                            <Link to="/regulamin" className={cx("text-white")}>
                                Regulamin
                            </Link>
                            <br />
                            <Link
                                to="/polityka-prywatnosci"
                                className={cx("text-white")}
                            >
                                Polityka prywatności
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={cx("bg-white", "py-3")}>
                <Container>
                    <Row>
                        <Col
                            xs="12"
                            className={cx("d-flex", "justify-content-center")}
                        >
                            <a
                                href="https://tuespetrus.pl/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    src={baner}
                                    alt="Fundacja Współpracy Tu es Petrus"
                                    className={cx("baner")}
                                />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    );
};

export interface FooterProps {}

export default Footer;
