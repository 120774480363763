import {
    Form,
    FormFeedback,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import React, { FormEvent } from "react";

import ApiError from "api/ApiError";
import Button from "components/elements/Button";
import FormField from "components/elements/FormField";
import KlientApi from "api/schemas/KlientApi";
// import classNames from "helpers/classNames";
import pobierzWartoscPola from "helpers/pobierzWartoscPola";
import { queryCache } from "react-query";
// import styles from "./FormularzEdycjiKlientaApi.module.scss";
import useApiCallback from "api/hooks/useApiCallback";

// const cx = classNames(styles);

const FormularzEdycjiKlientaApi: React.FC<FormularzEdycjiKlientaApiProps> = ({
    klient,
    isOpen,
    onClose,
}) => {
    const [zapisz, metadaneZapisu] = useApiCallback(
        `/klienci-api/${klient.guid}`,
        {
            method: "PUT",
        }
    );

    const wysylkaFormularza = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        zapisz(
            {
                nazwa: pobierzWartoscPola(form, "nazwa"),
                czy_aktywny: !!parseInt(
                    pobierzWartoscPola(form, "czy_aktywny")
                ),
            },
            {
                onSuccess: () => {
                    // wymuszenie przeładowania danych listy i szczegółów aktualności
                    queryCache.invalidateQueries("/klienci-api");
                    queryCache.invalidateQueries(`/klienci-api/${klient.guid}`);
                    // zamknięcie okna z formularzem
                    onClose();
                },
            }
        );
    };

    const bledyWalidacji =
        metadaneZapisu.error instanceof ApiError
            ? metadaneZapisu.error.validation || {}
            : {};
    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            backdrop={metadaneZapisu.status === "loading" ? "static" : true}
        >
            <ModalHeader>Nowy artykuł</ModalHeader>
            <Form onSubmit={wysylkaFormularza}>
                <ModalBody>
                    <FormField
                        required
                        disabled={metadaneZapisu.status === "loading"}
                        name="nazwa"
                        label="Nazwa"
                        maxLength={200}
                        error={bledyWalidacji.nazwa}
                        defaultValue={klient.nazwa}
                    />
                    <FormField
                        type="switch"
                        disabled={metadaneZapisu.status === "loading"}
                        name="czy_aktywny"
                        inlineLabel="Aktywnuj klienta"
                        helpText="Zaznacz to pole, aby aktywować klienta API i umożliwić mu wykonywanie zapytań."
                        error={bledyWalidacji.nazwa}
                        defaultChecked={!!klient.czy_aktywny}
                    />
                    {!!metadaneZapisu.error && (
                        <FormFeedback valid={false}>
                            {metadaneZapisu.error.message}
                        </FormFeedback>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        color="secondary"
                        outline
                        disabled={metadaneZapisu.status === "loading"}
                        onClick={onClose}
                        label="Anuluj"
                    />
                    <Button
                        color="secondary"
                        type="submit"
                        label="Zapisz"
                        disabled={metadaneZapisu.status === "loading"}
                        loading={metadaneZapisu.status === "loading"}
                    />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export interface FormularzEdycjiKlientaApiProps {
    isOpen: boolean;
    klient: KlientApi;
    onClose: () => void | any;
}

export default FormularzEdycjiKlientaApi;
