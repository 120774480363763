import { ClassNamesFn } from "classnames/types";
import bootstrap from "styles/bs.module.scss";
import classNames from "classnames/bind";

interface CSSModule {
    [key: string]: string;
}

const classNamesHelper = (cssModule: CSSModule): ClassNamesFn => {
    const classes = { ...bootstrap, ...cssModule };
    return classNames.bind(classes);
};

export default classNamesHelper;
