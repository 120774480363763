import React, { useState } from "react";
import authContext, {
    IAuthContext,
    IZalogujParams,
} from "helpers/context/auth";

import Preloader from "components/elements/Preloader";
import useApi from "api/hooks/useApi";
import useApiCallback from "api/hooks/useApiCallback";

const AuthServiceProvider: React.FC<AuthServiceProviderProps> = ({
    children,
}) => {
    const [trwaLogowanie, setTrwaLogowanie] = useState(false);
    const uzytkownik = useApi<IAuthContext>("/uzytkownik", {
        refetchOnWindowFocus: false,
    });
    const [executeLogin, loginState] = useApiCallback("/zaloguj", {
        csrf_token: uzytkownik.data?.csrf_token,
    });
    const [executeLogout, logoutState] = useApiCallback("/wyloguj", {
        csrf_token: uzytkownik.data?.csrf_token,
    });

    const zaloguj = async (daneLogowania: IZalogujParams, next?: string) => {
        if (loginState.status === "loading") {
            return;
        }

        setTrwaLogowanie(true);
        executeLogin(daneLogowania, {
            onSuccess: () => {
                let nextUrl = next || "/";
                if (nextUrl.indexOf("/") !== 0) {
                    nextUrl = "/";
                }

                setTimeout(() => {
                    window.location.href = nextUrl;
                }, 100);
            },
            onError: () => {
                setTrwaLogowanie(false);
            },
        });
    };

    const wyloguj = async () => {
        if (logoutState.status === "loading") {
            return;
        }

        await executeLogout(
            {},
            {
                onSuccess: () => {
                    window.location.href = "/logowanie";
                },
                onError: (error) => alert(error.message),
            }
        );
    };

    if (uzytkownik.status === "loading") {
        return <Preloader />;
    }

    if (uzytkownik.status !== "success") {
        return null;
    }

    const context: IAuthContext = {
        user: uzytkownik.data.user || null,
        csrf_token: uzytkownik.data.csrf_token || "",
        zaloguj: zaloguj,
        wyloguj: wyloguj,
        statusLogowania: {
            status: trwaLogowanie ? "loading" : loginState.status,
            error: loginState.error,
        },
        statusWylogowania: {
            status: logoutState.status,
            error: logoutState.error,
        },
    };

    return (
        <authContext.Provider value={context}>{children}</authContext.Provider>
    );
};

export interface AuthServiceProviderProps {}

export default AuthServiceProvider;
