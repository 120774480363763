import "@material/drawer/dist/mdc.drawer.css";

import { Drawer, DrawerContent } from "@rmwc/drawer";
import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import logo from "static/logo.png";
import Button from "components/elements/Button";
import { Container } from "reactstrap";
import classNames from "helpers/classNames";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styles from "./Header.module.scss";
import useAuth from "hooks/useAuth";
import { useMedia } from "react-use";

const cx = classNames(styles);

const Header: React.FC<HeaderProps> = () => {
    const { user, wyloguj } = useAuth();
    const { pathname } = useLocation();
    const [pokazMobilneMenu, setPokazMobilneMenu] = useState(false);
    const pokazMenuWNaglowku = useMedia("(min-width:768px");

    // ukrywanie menu przy przejściu na inny url
    useEffect(() => {
        setPokazMobilneMenu(false);
    }, [pathname]);
    const menu = (
        <>
            <NavLink
                activeClassName={cx("active")}
                exact
                to="/"
                className={cx("link")}
            >
                Strona główna
            </NavLink>
            <NavLink
                activeClassName={cx("active")}
                exact
                to="/aktualnosci"
                className={cx("link")}
            >
                Aktualności
            </NavLink>
            <NavLink
                activeClassName={cx("active")}
                exact
                to="/schematyzm-api"
                className={cx("link")}
            >
                API
            </NavLink>
            <NavLink
                activeClassName={cx("active")}
                exact
                to="/kontakt"
                className={cx("link")}
            >
                Kontakt
            </NavLink>
            {user ? (
                <Button
                    color="link"
                    onClick={wyloguj}
                    type="button"
                    className={cx("link")}
                    label="Wyloguj"
                />
            ) : (
                <NavLink
                    activeClassName={cx("active")}
                    exact
                    to={`/logowanie?next=${pathname}`}
                    className={cx("link")}
                >
                    Logowanie
                </NavLink>
            )}
        </>
    );

    return (
        <header className={cx("bg-white", "py-3", "shadow")}>
            <Container className={cx("navbar")}>
                {!!pokazMenuWNaglowku && (
                    <div className={cx("d-flex", "justify-content-center", "align-items-center")}>
                        <a href="/">
                            <img src={logo}
                                alt="Logo Fundacji Współpracy Tu es Petrus" 
                                className={cx("logo")}
                            />
                        </a>
                        <div className={cx("menu")}>
                            {menu}
                        </div>
                    </div>
                )}
                {!pokazMenuWNaglowku && (
                    <div className={cx("d-flex", "justify-content-between", "align-items-center")}>
                        <a href="/">
                            <img src={logo}
                                alt="Logo Fundacji Współpracy Tu es Petrus" 
                                className={cx("logo")}
                            />
                        </a>
                        <Button
                            color="primary"
                            className={cx("hamburger")}
                            icon={faBars}
                            outline
                            onClick={() => setPokazMobilneMenu(true)}
                        />
                    </div>
                )}
            </Container>
            {!pokazMenuWNaglowku && (
                <Drawer
                    className={cx("drawer", "p-3")}
                    modal
                    dir="rtl"
                    onClose={() => setPokazMobilneMenu(false)}
                    open={pokazMobilneMenu}
                >
                    <DrawerContent dir="ltr">{menu}</DrawerContent>
                </Drawer>
            )}
        </header>
    );
};

export interface HeaderProps {}

export default Header;
