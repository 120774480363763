import { Col, Container, Form, Row } from "reactstrap";
import React, { FormEvent } from "react";

import ApiError from "api/ApiError";
import Button from "components/elements/Button";
import FormField from "components/elements/FormField";
import ViewTitle from "components/elements/ViewTitle";
import classNames from "helpers/classNames";
import pobierzWartoscPola from "helpers/pobierzWartoscPola";
import styles from "./LoginView.module.scss";
import useAuth from "hooks/useAuth";
import useQueryString from "hooks/useQueryString";

const cx = classNames(styles);

const LoginView: React.FC<LoginViewProps> = () => {
    const [query] = useQueryString<{ next: string }>();
    const { zaloguj, statusLogowania } = useAuth();
    const wysylkaFormularza = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        zaloguj(
            {
                nazwa: pobierzWartoscPola(form, "nazwa"),
                haslo: pobierzWartoscPola(form, "haslo"),
            },
            query.next || "/"
        );
    };

    const bledyWalidacji =
        statusLogowania.error instanceof ApiError
            ? statusLogowania.error.validation
            : {};

    return (
        <Container className={cx("pt-5")}>
            <ViewTitle>Logowanie</ViewTitle>
            <Row className={cx("justify-content-center", "py-5")}>
                <Col md="8" lg="6">
                    <Form
                        onSubmit={wysylkaFormularza}
                        className={cx("rounded-lg", "border", "shadow", "p-3")}
                    >
                        <FormField
                            disabled={statusLogowania.status === "loading"}
                            name="nazwa"
                            label="Nazwa użytkownika"
                            error={bledyWalidacji.nazwa}
                        />
                        <FormField
                            disabled={statusLogowania.status === "loading"}
                            type="password"
                            name="haslo"
                            label="Hasło"
                            error={bledyWalidacji.haslo}
                        />
                        {!!statusLogowania.error && (
                            <p className={cx("text-danger")}>
                                {statusLogowania.error.message}
                            </p>
                        )}
                        <Button
                            color="secondary"
                            type="submit"
                            label="Zaloguj"
                            loading={statusLogowania.status === "loading"}
                        />
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export interface LoginViewProps {}

export default LoginView;
