import { Redirect, Route, Switch } from "react-router-dom";

import AktualnoscDetailsView from "./AktualnoscDetailsView";
import AktualnosciView from "./AktualnosciView";
import ApiView from "./ApiView";
import ArtisanView from "./ArtisanView";
import { Container } from "reactstrap";
import KlienciApiView from "./KlienciApiView";
import KlientApiDetailsView from "./KlientApiDetailsView";
import KontaktView from "./KontaktView";
import LoginView from "./LoginView";
import OSchematyzmieView from "./OSchematyzmieView";
import ParafiaDetailsView from "./ParafiaDetailsView";
import PodziekowaniaView from "./PodziekowaniaView";
import PolitykaPrywatnosciView from "./PolitykaPrywatnosciView";
import React from "react";
import RegulaminView from "./RegulaminView";
import StronaGlowna from "components/views/StronaGlowna";
import { TypKontaUzytkownika } from "api/schemas/Uzytkownik";
import View from "components/core/View";
import ViewTitle from "components/elements/ViewTitle";
import useAuth from "hooks/useAuth";

const Views = () => {
    const { user } = useAuth();
    return (
        <Switch>
            <Route exact path="/">
                <View>
                    <StronaGlowna />
                </View>
            </Route>
            <Redirect exact path="/parafie" to="/" />
            <Route exact path="/parafie/:guid">
                <View>
                    <ParafiaDetailsView />
                </View>
            </Route>
            <Route exact path="/aktualnosci">
                <View>
                    <AktualnosciView />
                </View>
            </Route>
            <Route exact path="/aktualnosci/:guid">
                <View>
                    <AktualnoscDetailsView />
                </View>
            </Route>
            <Route exact path="/schematyzm-api">
                <View>
                    <ApiView />
                </View>
            </Route>
            {user?.typ_konta === TypKontaUzytkownika.TYP_KONTA_ADMIN && (
                <Route exact path="/schematyzm-api/klienci">
                    <View>
                        <KlienciApiView />
                    </View>
                </Route>
            )}
            {user?.typ_konta === TypKontaUzytkownika.TYP_KONTA_ADMIN && (
                <Route exact path="/schematyzm-api/klienci/:guid">
                    <View>
                        <KlientApiDetailsView />
                    </View>
                </Route>
            )}
            {user?.typ_konta === TypKontaUzytkownika.TYP_KONTA_ADMIN && (
                <Route exact path="/artisan">
                    <View>
                        <ArtisanView />
                    </View>
                </Route>
            )}
            {!user && (
                <Route exact path="/logowanie">
                    <View>
                        <LoginView />
                    </View>
                </Route>
            )}
            <Route exact path="/kontakt">
                <View>
                    <KontaktView />
                </View>
            </Route>
            <Route exact path="/o-schematyzmie">
                <View>
                    <OSchematyzmieView />
                </View>
            </Route>
            <Route exact path="/podziekowania">
                <View>
                    <PodziekowaniaView />
                </View>
            </Route>
            <Route exact path="/polityka-prywatnosci">
                <View>
                    <PolitykaPrywatnosciView />
                </View>
            </Route>
            <Route exact path="/regulamin">
                <View>
                    <RegulaminView />
                </View>
            </Route>
            <Route>
                <View>
                    <Container>
                        <ViewTitle>404 - nie znaleziono</ViewTitle>
                    </Container>
                </View>
            </Route>
        </Switch>
    );
};

export default Views;
