import { API_KEY } from "api/constants";
import getApiUrl from "api/getApiUrl";
import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";
import qs from "qs";

const otworzEksport = (
    wyszukiwanie: ParafieFiltrParams["wyszukiwanie"],
    filtr: ParafieFiltrParams["filtr"],
    diecezja: string = "",
    dekanat: string = ""
) => {
    const query = qs.stringify(
        { wyszukiwanie, filtr, diecezja, dekanat, "api-key": API_KEY },
        { arrayFormat: "brackets" }
    );
    const url = getApiUrl(`/parafie/export?${query}`);
    window.open(url, "_blank", "noopener noreferrer");
};

export default otworzEksport;
