import { Col, Row } from "reactstrap";
import React, { ReactNode } from "react";

import classNames from "helpers/classNames";
import styles from "./LabeledInfo.module.scss";

const cx = classNames(styles);

const checkIsEmpty = (children: ReactNode) => {
    const arr = React.Children.toArray(children);
    return arr.length === 0;
};

const LabeledInfo: React.FC<ILabeledInfoProps> = ({
    className = "",
    label = "",
    children = null,
    placeholder = "---",
}) => {
    const isEmpty = checkIsEmpty(children);
    return (
        <Row className={cx(className, "border-bottom", "align-items-center")}>
            <Col xs="12" lg="7" className={cx("py-2")}>
                <strong>{label}:</strong>
            </Col>
            <Col xs="12" lg="5" className={cx("py-2")}>
                {isEmpty ? placeholder : children}
            </Col>
        </Row>
    );
};

interface ILabeledInfoProps {
    className?: string;
    label?: string;
    placeholder?: string;
}

export default LabeledInfo;
