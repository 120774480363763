import useApi, { UseApiOptions } from "./useApi";

import DiecezjaListItem from "api/schemas/DiecezjaListItem";
import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";

const useDiecezje = (
    params: ParafieFiltrParams = {},
    options?: UseApiOptions<DiecezjeApiResponse>
) => {
    const res = useApi<DiecezjeApiResponse>("/diecezje", {
        params,
        ...options,
    });

    return res;
};

export type DiecezjeApiResponse = {
    data: DiecezjaListItem[];
};

export default useDiecezje;
