import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";

type UseCzyPozycjaRozwinietaHook = [boolean, Dispatch<SetStateAction<boolean>>];

const sprawdzFiltry = (
    wyszukiwanie?: ParafieFiltrParams["wyszukiwanie"],
    filtr?: ParafieFiltrParams["filtr"]
) => {
    return (
        !!wyszukiwanie ||
        Object.values(filtr || {}).filter((i) => !!i).length > 0
    );
};

const useCzyPozycjaRozwinieta = (
    wyszukiwanie?: ParafieFiltrParams["wyszukiwanie"],
    filtr?: ParafieFiltrParams["filtr"]
): UseCzyPozycjaRozwinietaHook => {
    // sprawdz czy ma filtry na starcie
    const maFiltry = useMemo(
        () => sprawdzFiltry(wyszukiwanie, filtr || {}),
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // ustaw status otwarcia na podstawie poczatkowych filtrow
    const [rozwinieta, setRozwinieta] = useState(!!maFiltry);

    // przelacz status otwarcia przy zmianie filtrow
    useEffect(() => {
        const powinnaBycRozwinieta = sprawdzFiltry(wyszukiwanie, filtr || {});
        if (powinnaBycRozwinieta && !rozwinieta) {
            setRozwinieta(true);
        }
    }, [wyszukiwanie, filtr]); // eslint-disable-line react-hooks/exhaustive-deps

    return [rozwinieta, setRozwinieta];
};

export default useCzyPozycjaRozwinieta;
