import useApi, { UseApiOptions } from "./useApi";

import KlientApi from "api/schemas/KlientApi";
import ListingApiResponse from "api/schemas/ListingApiResponse";

const useKlienciApi = (
    params: AktualosciListingParams = {},
    options?: UseApiOptions<KlienciApiApiResponse>
) => {
    const res = useApi<KlienciApiApiResponse>("/klienci-api", {
        params,
        ...options,
    });

    return res;
};

export type AktualosciListingParams = {
    strona?: number;
    na_stronie?: number;
};

export type KlienciApiApiResponse = ListingApiResponse<KlientApi>;

export default useKlienciApi;
