import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Aktualnosc from "api/schemas/Aktualnosc";
import Button from "components/elements/Button";
import React from "react";
// import classNames from "helpers/classNames";
import { queryCache } from "react-query";
// import styles from "./ModalUsuwaniaAktualnosci.module.scss";
import useApiCallback from "api/hooks/useApiCallback";
import { useHistory } from "react-router-dom";

// const cx = classNames(styles);

const ModalUsuwaniaAktualnosci: React.FC<FormularzEdycjiAktualnosciProps> = ({
    artykul,
    isOpen,
    onClose,
}) => {
    const { push } = useHistory();
    const [
        usun,
        metadaneUsuwania,
    ] = useApiCallback(`/aktualnosci/${artykul.guid}`, { method: "DELETE" });

    const wysylkaFormularza = () => {
        usun(
            {},
            {
                onSuccess: () => {
                    // wymuszenie przeładowania danych listy i szczegółów aktualności
                    queryCache.invalidateQueries("/aktualnosci");
                    // zamknięcie wyskakującego okna
                    onClose();
                    // przeniesienie na widok listy aktualności
                    push("/aktualnosci");
                },
            }
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            backdrop={metadaneUsuwania.status === "loading" ? "static" : true}
        >
            <ModalHeader>Usuwanie artykułu</ModalHeader>
            <ModalBody>
                <p>Czy na pewno chcesz usunąć artykuł "{artykul.tytul}"?</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    outline
                    disabled={metadaneUsuwania.status === "loading"}
                    onClick={onClose}
                    label="Anuluj"
                />
                <Button
                    onClick={() => wysylkaFormularza()}
                    label="Usuń"
                    color="danger"
                    disabled={metadaneUsuwania.status === "loading"}
                    loading={metadaneUsuwania.status === "loading"}
                />
            </ModalFooter>
        </Modal>
    );
};

export interface FormularzEdycjiAktualnosciProps {
    isOpen: boolean;
    artykul: Aktualnosc;
    onClose: () => void | any;
}

export default ModalUsuwaniaAktualnosci;
