import {
    Form,
    FormFeedback,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import React, { FormEvent, useEffect, useState } from "react";
import {
    faTrashAlt,
    faTrashRestoreAlt,
} from "@fortawesome/free-solid-svg-icons";

import Aktualnosc from "api/schemas/Aktualnosc";
import ApiError from "api/ApiError";
import Button from "components/elements/Button";
import FormField from "components/elements/FormField";
import Thumbnail from "components/elements/Thumbnail";
import classNames from "helpers/classNames";
import pobierzPlikPola from "helpers/pobierzPlikPola";
import pobierzWartoscPola from "helpers/pobierzWartoscPola";
import { queryCache } from "react-query";
import styles from "./FormularzEdycjiAktualnosci.module.scss";
import useApiCallback from "api/hooks/useApiCallback";

const cx = classNames(styles);

const FormularzEdycjiAktualnosci: React.FC<FormularzEdycjiAktualnosciProps> = ({
    artykul,
    isOpen,
    onClose,
}) => {
    // obsługa edycji pola z dotychczasowym obrazkiem
    const [obrazek, setObrazek] = useState(artykul.obrazek);

    useEffect(() => {
        setObrazek(artykul.obrazek);
    }, [artykul.obrazek]);

    const [
        zapisz,
        metadaneZapisu,
    ] = useApiCallback(`/aktualnosci/${artykul.guid}`, { method: "PUT" });

    const wysylkaFormularza = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        zapisz(
            {
                tytul: pobierzWartoscPola(form, "tytul"),
                zajawka: pobierzWartoscPola(form, "zajawka"),
                tresc: pobierzWartoscPola(form, "tresc"),
                obrazek: obrazek,
                nowy_obrazek: pobierzPlikPola(form, "nowy_obrazek"),
            },
            {
                onSuccess: () => {
                    // wymuszenie przeładowania danych listy i szczegółów aktualności
                    queryCache.invalidateQueries("/aktualnosci");
                    queryCache.invalidateQueries(
                        `/aktualnosci/${artykul.guid}`
                    );
                    // zamknięcie okna z formularzem
                    onClose();
                },
            }
        );
    };

    const bledyWalidacji =
        metadaneZapisu.error instanceof ApiError
            ? metadaneZapisu.error.validation || {}
            : {};
    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            toggle={onClose}
            backdrop={metadaneZapisu.status === "loading" ? "static" : true}
        >
            <ModalHeader>Edycja artykułu</ModalHeader>
            <Form onSubmit={wysylkaFormularza}>
                <ModalBody>
                    <FormField
                        required
                        disabled={metadaneZapisu.status === "loading"}
                        name="tytul"
                        label="Tytuł"
                        maxLength={200}
                        error={bledyWalidacji.tytul}
                        defaultValue={artykul.tytul}
                    />
                    <Label>Obrazek</Label>
                    <div>
                        {!!obrazek && (
                            <div className={cx("thumbnailContainer", "my-2")}>
                                <Thumbnail
                                    className={cx("thumbnail")}
                                    image={obrazek}
                                />
                                <Button
                                    className={cx("deleteIcon")}
                                    size="sm"
                                    type="button"
                                    color="danger"
                                    icon={faTrashAlt}
                                    onClick={() => setObrazek("")}
                                    disabled={
                                        metadaneZapisu.status === "loading"
                                    }
                                    title="Usuń obrazek"
                                />
                            </div>
                        )}
                        {!obrazek && !!artykul.obrazek && (
                            <div className={cx("mb-2")}>
                                Dotychczasowy zostanie usunięty po zapisaniu
                                zmian.
                                <Button
                                    className={cx("ml-2")}
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    icon={faTrashRestoreAlt}
                                    onClick={() => setObrazek(artykul.obrazek)}
                                    disabled={
                                        metadaneZapisu.status === "loading"
                                    }
                                    label="Przywróć obrazek"
                                />
                            </div>
                        )}
                    </div>
                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="nowy_obrazek"
                        inlineLabel={
                            obrazek ? "Wybierz nowy obrazek" : "Wybierz obrazek"
                        }
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        capture="environment"
                        error={bledyWalidacji.nowy_obrazek}
                    />
                    <FormField
                        required
                        disabled={metadaneZapisu.status === "loading"}
                        name="zajawka"
                        label="Zajawka"
                        helpText="Tekst wprowadzający, widoczny na liście aktualności."
                        type="textarea"
                        rows={5}
                        maxLength={500}
                        error={bledyWalidacji.zajawka}
                        defaultValue={artykul.zajawka}
                    />
                    <FormField
                        required
                        disabled={metadaneZapisu.status === "loading"}
                        name="tresc"
                        label="Treść"
                        type="textarea"
                        rows={20}
                        error={bledyWalidacji.tresc}
                        defaultValue={artykul.tresc}
                    />
                    {!!metadaneZapisu.error && (
                        <FormFeedback valid={false}>
                            {metadaneZapisu.error.message}
                        </FormFeedback>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        color="secondary"
                        outline
                        disabled={metadaneZapisu.status === "loading"}
                        onClick={onClose}
                        label="Anuluj"
                    />
                    <Button
                        color="secondary"
                        type="submit"
                        label="Zapisz"
                        disabled={metadaneZapisu.status === "loading"}
                        loading={metadaneZapisu.status === "loading"}
                    />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export interface FormularzEdycjiAktualnosciProps {
    isOpen: boolean;
    artykul: Aktualnosc;
    onClose: () => void | any;
}

export default FormularzEdycjiAktualnosci;
