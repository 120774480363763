import { Col, Collapse, Form, Row } from "reactstrap";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { faFileCsv, faFilter } from "@fortawesome/free-solid-svg-icons";

import Button from "components/elements/Button";
import FormField from "components/elements/FormField";
import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";
import _isEqual from "lodash/isEqual";
import classNames from "helpers/classNames";
import styles from "./SchematyzmFiltry.module.scss";
import useQueryString from "hooks/useQueryString";
import useAuth from "hooks/useAuth";
import otworzEksport from "helpers/otworzEksport";

const cx = classNames(styles);
enum ATRYBUTY_FILTROWANIA {
    nazwa = "Nazwa",
    nazwa_skrocona = "Nazwa skrócona",
    nazwa_historyczna = "Nazwa historyczna",
    nazwa_zwyczajowa = "Nazwa zwyczajowa",
    ulica = "Ulica",
    nr_posesji = "Nr posesji",
    miejscowosc = "Miejscowość",
    kod_pocztowy = "Kod pocztowy",
    gmina = "Gmina",
    powiat = "Powiat",
    wojewodztwo = "Województwo",
    czy_adres_zsynchronizowany = "Czy adres zsynchronizowany? (1-tak, 0-nie)",
    uwagi = "Uwagi",
}

const ATRYBUTY_FILTROWANIA_ZALOGOWANI = ["czy_adres_zsynchronizowany", "uwagi"];

const SchematyzmFiltry: React.FC<SchematyzmFiltryProps> = ({
    onWyszukiwanie,
    onFiltr,
}) => {
    const searchInput = useRef<HTMLInputElement>(null);
    const wyszukiwanieTimeout = useRef<any>(null);
    const [query] = useQueryString<ParafieFiltrParams>();
    const [rozwinFiltry, setRozwinFiltry] = useState(false);
    const [wyszukiwanie, setWyszukiwanie] = useState<
        ParafieFiltrParams["wyszukiwanie"]
    >(query.wyszukiwanie || "");
    const [filtr, setFiltr] = useState<ParafieFiltrParams["filtr"]>(
        query.filtr || {}
    );

    // debounce przesyłania aktualnej wartości wyszukiwania do rodzica
    // po zmianie w polu wyszukiwania wartosc wyszukiwania zostanie
    // przekazana do rodzica dopiero po okreslonym czasie od zaprzestania pisania
    useEffect(() => {
        // anulowanie aktualnego timeoutu
        if (wyszukiwanieTimeout.current) {
            clearTimeout(wyszukiwanieTimeout.current);
        }

        // ustawienie nowego timeoutu, po ktorym wyszukiwanie zostanie przekazane do rodzica
        wyszukiwanieTimeout.current = setTimeout(
            () => onWyszukiwanie(wyszukiwanie),
            300
        );
        // zależności przy których zmianie funkcja useEffect ma zostać wykonana ponownie
    }, [wyszukiwanie]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (query.wyszukiwanie !== wyszukiwanie) {
            setWyszukiwanie(query.wyszukiwanie || "");
        }
        if (!_isEqual(query.filtr || {}, filtr)) {
            setFiltr(query.filtr || {});
        }
    }, [query.wyszukiwanie, JSON.stringify(query.filtr)]); // eslint-disable-line react-hooks/exhaustive-deps

    const { user } = useAuth();

    const przetworzZmianeFiltra = (e: FormEvent<HTMLInputElement>) => {
        const nazwa = e.currentTarget.name;
        const wartosc = e.currentTarget.value || undefined;
        setFiltr((poprzedniFiltr) => ({ ...poprzedniFiltr, [nazwa]: wartosc }));
    };

    const zatwierdzFiltrowanie = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onFiltr(filtr);
    };

    return (
        <>
            <Row form>
                <Col xs="12" lg="6">
                    <FormField
                        placeholder="Wyszukaj..."
                        name="wyszukaj"
                        value={wyszukiwanie}
                        innerRef={searchInput}
                        onChange={(e) => setWyszukiwanie(e.target.value)}
                        onClear={() => setWyszukiwanie("")}
                    />
                </Col>
                <Col lg />

                <Col xs="6" lg="auto" className={cx("mb-3")}>
                    <Button
                        type="button"
                        color="secondary"
                        label={rozwinFiltry ? "Zwiń filtry" : "Rozwiń filtry"}
                        onClick={() => setRozwinFiltry((rozwin) => !rozwin)}
                        icon={faFilter}
                    />
                </Col>
                <Col xs="6" lg="auto" className={cx("mb-3", "text-right")}>
                    <Button
                        type="button"
                        color="green"
                        label={"Eksportuj CSV"}
                        onClick={() => otworzEksport(wyszukiwanie, filtr)}
                        icon={faFileCsv}
                    />
                </Col>
            </Row>
            <Collapse
                className={cx(
                    "p-3",
                    "pb-0",
                    "rounded",
                    "rounded-lg",
                    "shadow",
                    "border",
                    "mb-3"
                )}
                isOpen={rozwinFiltry}
            >
                <Form onSubmit={zatwierdzFiltrowanie}>
                    <Row form>
                        {Object.keys(ATRYBUTY_FILTROWANIA)
                            .filter((atrybut) => {
                                if (
                                    ATRYBUTY_FILTROWANIA_ZALOGOWANI.includes(
                                        atrybut
                                    ) &&
                                    !user
                                ) {
                                    return false;
                                }
                                return true;
                            })
                            .map((atrybut) => (
                                <Col key={atrybut} xs="12" lg="6">
                                    <FormField
                                        bsSize="sm"
                                        label={
                                            ATRYBUTY_FILTROWANIA[
                                                atrybut as keyof typeof ATRYBUTY_FILTROWANIA
                                            ]
                                        }
                                        value={
                                            (filtr || {})[
                                                atrybut as keyof ParafieFiltrParams["filtr"]
                                            ] || ""
                                        }
                                        name={atrybut}
                                        onChange={(e) =>
                                            przetworzZmianeFiltra(e)
                                        }
                                    />
                                </Col>
                            ))}
                    </Row>
                    <Row className={cx("justify-content-end")}>
                        <Col xs="12" lg="auto" className={cx("mb-3", "")}>
                            <Button
                                color="secondary"
                                className={cx("w-100")}
                                type="submit"
                                label="Zatwierdź filtry"
                            />
                        </Col>
                    </Row>
                </Form>
            </Collapse>
        </>
    );
};

export interface SchematyzmFiltryProps {
    onWyszukiwanie: (
        wyszukiwanie: ParafieFiltrParams["wyszukiwanie"]
    ) => void | any;
    onFiltr: (filtr: ParafieFiltrParams["filtr"]) => void | any;
}

export default SchematyzmFiltry;
