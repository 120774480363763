import {
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import React, { FormEvent } from "react";

import ApiError from "api/ApiError";
import Button from "components/elements/Button";
import FormField from "components/elements/FormField";
import ParafiaListItem from "api/schemas/ParafiaListItem";
import classNames from "helpers/classNames";
import pobierzPlikPola from "helpers/pobierzPlikPola";
import pobierzWartoscPola from "helpers/pobierzWartoscPola";
import { queryCache } from "react-query";
import styles from "./EdycjaParafiiModal.module.scss";
import useApiCallback from "api/hooks/useApiCallback";

const cx = classNames(styles);

const EdycjaParafiiModal: React.FC<EdycjaParafiiModalProps> = ({
    parafia,
    isOpen,
    onClose,
}) => {
    const [zapisz, metadaneZapisu] = useApiCallback(
        `/parafie/${parafia.guid}`,
        { method: "PUT" }
    );

    const wysylkaFormularza = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        zapisz(
            {
                nazwa: pobierzWartoscPola(form, "nazwa"),
                nazwa_skrocona: pobierzWartoscPola(form, "nazwa_skrocona"),
                nazwa_historyczna: pobierzWartoscPola(
                    form,
                    "nazwa_historyczna"
                ),
                nazwa_zwyczajowa: pobierzWartoscPola(form, "nazwa_zwyczajowa"),
                email: pobierzWartoscPola(form, "email"),
                czy_email_zweryfikowany: pobierzWartoscPola(
                    form,
                    "czy_email_zweryfikowany"
                ),
                telefon: pobierzWartoscPola(form, "telefon"),
                czy_telefon_zweryfikowany: pobierzWartoscPola(
                    form,
                    "czy_telefon_zweryfikowany"
                ),
                ulica: pobierzWartoscPola(form, "ulica"),
                nr_posesji: pobierzWartoscPola(form, "nr_posesji"),
                miejscowosc: pobierzWartoscPola(form, "miejscowosc"),
                kod_pocztowy: pobierzWartoscPola(form, "kod_pocztowy"),
                czy_synchronizowac_teryt: pobierzWartoscPola(
                    form,
                    "czy_synchronizowac_teryt"
                ),
                gmina: pobierzWartoscPola(form, "gmina"),
                powiat: pobierzWartoscPola(form, "powiat"),
                wojewodztwo: pobierzWartoscPola(form, "wojewodztwo"),
                strona_www: pobierzWartoscPola(form, "strona_www"),
                adres_uniwersalny: pobierzWartoscPola(
                    form,
                    "adres_uniwersalny"
                ),
                biuro_parafialne: pobierzWartoscPola(form, "biuro_parafialne"),
                regon: pobierzWartoscPola(form, "regon"),
                nip: pobierzWartoscPola(form, "nip"),
                nr_konta: pobierzWartoscPola(form, "nr_konta"),
                data_erygowania: pobierzWartoscPola(form, "data_erygowania"),
                dokument_erygujacy: pobierzPlikPola(
                    form,
                    "nowy_dokument_erygujacy"
                ),
                swieto_patronalne: pobierzWartoscPola(
                    form,
                    "swieto_patronalne"
                ),
                nota_historyczna: pobierzWartoscPola(form, "nota_historyczna"),
                uwagi: pobierzWartoscPola(form, "uwagi"),
            },
            {
                onSuccess: () => {
                    // wymuszenie przeładowania danych listy i szczegółów parafii
                    queryCache.invalidateQueries("/parafie");
                    queryCache.invalidateQueries(`/parafie/${parafia.guid}`);
                    // zamknięcie okna z formularzem
                    onClose();
                },
            }
        );
    };

    const bledyWalidacji =
        metadaneZapisu.error instanceof ApiError
            ? metadaneZapisu.error.validation || {}
            : {};
    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            size="lg"
            backdrop={metadaneZapisu.status === "loading" ? "static" : true}
        >
            <ModalHeader tag="span" toggle={onClose}>
                <h3 className={cx("h5")}>{parafia.nazwa} - edycja</h3>
            </ModalHeader>
            <Form onSubmit={wysylkaFormularza}>
                <ModalBody>
                    <Row form>
                        <FormField
                            className={cx("col-12")}
                            required
                            disabled={metadaneZapisu.status === "loading"}
                            name="nazwa"
                            defaultValue={parafia.nazwa || ""}
                            label="Nazwa"
                            error={bledyWalidacji.nazwa}
                        />
                        <FormField
                            className={cx("col-12")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="nazwa_skrocona"
                            defaultValue={parafia.nazwa_skrocona || ""}
                            label="Nazwa skrócona"
                            error={bledyWalidacji.nazwa_skrocona}
                        />
                        <FormField
                            className={cx("col-12")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="nazwa_historyczna"
                            defaultValue={parafia.nazwa_historyczna || ""}
                            label="Nazwa historyczna"
                            error={bledyWalidacji.nazwa_historyczna}
                        />
                        <FormField
                            className={cx("col-12")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="nazwa_zwyczajowa"
                            defaultValue={parafia.nazwa_zwyczajowa || ""}
                            label="Nazwa zwyczajowa"
                            error={bledyWalidacji.nazwa_zwyczajowa}
                        />
                        <FormField
                            className={cx("col-12", "col-lg-8")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="adres_uniwersalny"
                            defaultValue={parafia.adres_uniwersalny || ""}
                            label="Adres uniwersalny"
                            error={bledyWalidacji.adres_uniwersalny}
                        />
                        <FormField
                            className={cx("col-12", "col-lg-4")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="czy_synchronizowac_teryt"
                            defaultChecked={
                                parafia.czy_synchronizowac_teryt || ""
                            }
                            label="Synchronizować adres z TERYT?"
                            type="switch"
                            inlineLabel="(nie/ tak)"
                            error={bledyWalidacji.czy_synchronizowac_teryt}
                        />
                    </Row>
                    <Row form>
                        <Col xs="8" md="10">
                            <FormField
                                disabled={
                                    metadaneZapisu.status === "loading" ||
                                    parafia.czy_synchronizowac_teryt === true
                                }
                                name="ulica"
                                defaultValue={parafia.ulica || ""}
                                label="Ulica"
                                error={bledyWalidacji.ulica}
                            />
                        </Col>
                        <Col xs="4" md="2">
                            <FormField
                                disabled={
                                    metadaneZapisu.status === "loading" ||
                                    parafia.czy_synchronizowac_teryt === true
                                }
                                name="nr_posesji"
                                defaultValue={parafia.nr_posesji || ""}
                                label="Nr posesji"
                                error={bledyWalidacji.nr_posesji}
                            />
                        </Col>
                    </Row>

                    <Row form>
                        <Col xs="12" md="4">
                            <FormField
                                disabled={
                                    metadaneZapisu.status === "loading" ||
                                    parafia.czy_synchronizowac_teryt === true
                                }
                                name="kod_pocztowy"
                                defaultValue={parafia.kod_pocztowy || ""}
                                label="Kod pocztowy"
                                error={bledyWalidacji.kod_pocztowy}
                            />
                        </Col>
                        <Col xs="12" md="8">
                            <FormField
                                disabled={
                                    metadaneZapisu.status === "loading" ||
                                    parafia.czy_synchronizowac_teryt === true
                                }
                                name="miejscowosc"
                                defaultValue={parafia.miejscowosc || ""}
                                label="Miejscowość"
                                error={bledyWalidacji.miejscowosc}
                            />
                        </Col>
                    </Row>

                    <Row form>
                        <FormField
                            className={cx("col-12")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="gmina"
                            defaultValue={parafia.gmina || ""}
                            label="Gmina"
                            error={bledyWalidacji.gmina}
                        />
                        <FormField
                            className={cx("col-12")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="powiat"
                            defaultValue={parafia.powiat || ""}
                            label="Powiat"
                            error={bledyWalidacji.powiat}
                        />
                        <FormField
                            className={cx("col-12")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="wojewodztwo"
                            defaultValue={parafia.wojewodztwo || ""}
                            label="Województwo"
                            error={bledyWalidacji.wojewodztwo}
                        />

                        <FormField
                            className={cx("col-12", "col-md-8")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="email"
                            defaultValue={parafia.email || ""}
                            label="Email"
                            type="email"
                            error={bledyWalidacji.email}
                        />
                        <FormField
                            className={cx("col-12", "col-md-4")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="czy_email_zweryfikowany"
                            defaultChecked={
                                parafia.czy_email_zweryfikowany || ""
                            }
                            label="Email zweryfikowany?"
                            type="switch"
                            inlineLabel="(nie/ tak)"
                            error={bledyWalidacji.czy_email_zweryfikowany}
                        />
                        <FormField
                            className={cx("col-12", "col-md-8")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="telefon"
                            defaultValue={parafia.telefon || ""}
                            label="Telefon"
                            type="tel"
                            error={bledyWalidacji.telefon}
                        />
                        <FormField
                            className={cx("col-12", "col-md-4")}
                            disabled={metadaneZapisu.status === "loading"}
                            name="czy_telefon_zweryfikowany"
                            defaultChecked={
                                parafia.czy_telefon_zweryfikowany || ""
                            }
                            label="Telefon zweryfikowany?"
                            type="switch"
                            inlineLabel="(nie/ tak)"
                            error={bledyWalidacji.czy_telefon_zweryfikowany}
                        />
                    </Row>

                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="strona_www"
                        defaultValue={parafia.strona_www || ""}
                        label="Strona WWW"
                        error={bledyWalidacji.strona_www}
                    />
                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="nr_konta"
                        defaultValue={parafia.nr_konta || ""}
                        label="Numer konta bankowego"
                        error={bledyWalidacji.nr_konta}
                    />
                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="nip"
                        defaultValue={parafia.nip || ""}
                        label="NIP"
                        minLength={10}
                        maxLength={10}
                        error={bledyWalidacji.nip}
                    />
                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="regon"
                        defaultValue={parafia.regon || ""}
                        label="REGON"
                        error={bledyWalidacji.regon}
                    />
                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="data_erygowania"
                        defaultValue={parafia.data_erygowania || ""}
                        label="Data erygowania"
                        error={bledyWalidacji.data_erygowania}
                    />
                    <FormField
                        label="Dokument erygujący"
                        disabled={metadaneZapisu.status === "loading"}
                        name="nowy_dokument_erygujacy"
                        inlineLabel={" "}
                        type="file"
                        accept=".png,.jpg,.jpeg,.pdf"
                        capture="environment"
                        error={bledyWalidacji.dokument_erygujacy}
                    />
                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="swieto_patronalne"
                        defaultValue={parafia.swieto_patronalne || ""}
                        label="Święto patronalne"
                        error={bledyWalidacji.swieto_patronalne}
                    />
                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="biuro_parafialne"
                        defaultValue={parafia.biuro_parafialne || ""}
                        label="Biuro parafialne"
                        type="textarea"
                        rows={8}
                        error={bledyWalidacji.biuro_parafialne}
                    />
                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="nota_historyczna"
                        defaultValue={parafia.nota_historyczna || ""}
                        label="Nota historyczna"
                        type="textarea"
                        rows={12}
                        error={bledyWalidacji.nota_historyczna}
                    />
                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="uwagi"
                        defaultValue={parafia.uwagi || ""}
                        label="Uwagi"
                        type="textarea"
                        rows={12}
                        error={bledyWalidacji.uwagi}
                    />
                    {!!metadaneZapisu.error && (
                        <p className={cx("text-danger")}>
                            {metadaneZapisu.error.message}
                        </p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        outline
                        onClick={onClose}
                        label="Anuluj"
                    />
                    <Button
                        type="submit"
                        label="Zapisz"
                        loading={metadaneZapisu.status === "loading"}
                    />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export interface EdycjaParafiiModalProps {
    isOpen: boolean;
    onClose: () => void | any;
    parafia: ParafiaListItem;
}

export default EdycjaParafiiModal;
