import AuthServiceProvider from "components/core/AuthServiceProvider";
import { BrowserRouter } from "react-router-dom";
import CookieFirst from "react-cookiefirst";
import Footer from "components/core/Footer";
import Header from "components/core/Header";
import React from "react";
import { ReactQueryConfigProvider } from "react-query";
import { Util } from "reactstrap";
import Views from "components/views";
import bootstrap from "styles/bs.module.scss";
import classNames from "helpers/classNames";
import executeRequest from "api/executeRequest";
import styles from "./App.module.scss";

const cx = classNames(styles);
Util.setGlobalCssModule(bootstrap);

const App: React.FC<AppProps> = () => {
    return (
        <ReactQueryConfigProvider
            config={{
                queries: {
                    // @ts-ignore
                    queryFn: executeRequest,
                },
            }}
        >
            <AuthServiceProvider>
                <BrowserRouter>
                    <Header />
                    <main className={cx("pb-5")}>
                        <Views />
                    </main>
                    <Footer />
                </BrowserRouter>
            </AuthServiceProvider>
            <CookieFirst apiKey="669f7832-305e-4a6b-a602-2004676e8a08" />
        </ReactQueryConfigProvider>
    );
};

export interface AppProps {}

export default App;
