import Button from "components/elements/Button";
import NewsCard from "./Components/NewsCard";
import Preloader from "components/elements/Preloader";
import React from "react";
import classNames from "helpers/classNames";
import styles from "./News.module.scss";
import useAktualnosci from "api/hooks/useAktualnosci";
import { useHistory } from "react-router-dom";

const cx = classNames(styles);

const News: React.FC<NewsProps> = () => {
    const { push } = useHistory();
    const aktualnosci = useAktualnosci({
        na_stronie: 3,
    });

    if (aktualnosci.status === "loading") {
        return (
            <div className={cx("mt-3", "rounded", "border", "shadow")}>
                <Preloader />
            </div>
        );
    }

    if (aktualnosci.status !== "success") {
        return <></>;
    }

    return (
        <>
            <div className={cx("mt-3", "rounded", "border", "shadow")}>
                {aktualnosci.data.data.map((artykul, index) => (
                    <React.Fragment key={artykul.guid}>
                        <NewsCard
                            title={artykul.tytul}
                            date={artykul.czas_utworzenia}
                            image={artykul.obrazek}
                            link={`/aktualnosci/${artykul.guid}`}
                        >
                            {artykul.zajawka || ""}
                        </NewsCard>
                        {index < aktualnosci.data.data.length - 1 && (
                            <hr className={cx("border-top", "w-100", "my-0")} />
                        )}
                    </React.Fragment>
                ))}
            </div>
            <Button
                color="secondary"
                className={cx("mt-3", "w-100")}
                onClick={() => push("/aktualnosci")}
                label="Zobacz wszystkie"
            />
        </>
    );
};

export interface NewsProps {}

export default News;
