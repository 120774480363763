export enum TypKontaUzytkownika {
    TYP_KONTA_ADMIN = 1,
    TYP_KONTA_DIECEZJA = 2,
}

interface Uzytkownik {
    email: string;
    guid: string;
    diecezja_id: number | null;
    typ_konta: TypKontaUzytkownika;
    nazwa: string;
}

export interface IUzytkownikApiResponse {
    user: Uzytkownik | null;
    csrf_token: string;
}

export default Uzytkownik;
