import ApiError from "api/ApiError";
import { IUzytkownikApiResponse } from "api/schemas/Uzytkownik";
import { QueryStatus } from "react-query";
import { createContext } from "react";

export interface IZalogujParams {
    nazwa: string;
    haslo: string;
}

export interface IAuthContext extends IUzytkownikApiResponse {
    zaloguj: (daneLogowania: IZalogujParams, next?: string) => any;
    statusLogowania: {
        status: QueryStatus;
        error: ApiError | Error | null | undefined;
    };
    wyloguj: () => any;
    statusWylogowania: {
        status: QueryStatus;
        error: ApiError | Error | null | undefined;
    };
}

export default createContext<IAuthContext>({
    user: null,
    csrf_token: "",
    statusLogowania: {
        status: "idle",
        error: null,
    },
    zaloguj: () => null,
    statusWylogowania: {
        status: "idle",
        error: null,
    },
    wyloguj: () => null,
});
