import { Button, Col, Container, Form, Row } from "reactstrap";
import React, { FormEvent } from "react";

import FormField from "components/elements/FormField";
import ViewTitle from "components/elements/ViewTitle";
import classNames from "helpers/classNames";
import styles from "./KontaktView.module.scss";
import useDiecezje from "api/hooks/useDiecezje";

const cx = classNames(styles);

const KontaktView: React.FC<KontaktViewProps> = () => {
    const diecezje = useDiecezje();
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        console.log({ form });
    };
    return (
        <Container className={cx("mt-5")}>
            <ViewTitle>Kontakt</ViewTitle>
            <Row className={cx("justify-content-center", "py-5")}>
                <Col md="8" lg="6">
                    <Form
                        onSubmit={handleSubmit}
                        className={cx("rounded-lg", "border", "shadow", "p-3")}
                    >
                        <FormField
                            label="Czy wiadomość dotyczy jednej z diecezji?"
                            required
                            name="diecezja"
                            type="select"
                            disabled={diecezje.status === "loading"}
                        >
                            {diecezje.status === "loading" && (
                                <option disabled value="">
                                    Ładowanie...
                                </option>
                            )}
                            {diecezje.status !== "loading" && (
                                <>
                                    <option value="0">
                                        Nie wybrano (wiadomość ogólna)
                                    </option>
                                    {diecezje.data?.data.map((diecezja) => (
                                        <option
                                            key={diecezja.guid}
                                            value={diecezja.guid}
                                        >
                                            {diecezja.nazwa}
                                        </option>
                                    ))}
                                </>
                            )}
                        </FormField>
                        <FormField
                            // disabled={statusLogowania.status === "loading"}
                            name="email"
                            label="Email"
                            required
                            // error={bledyWalidacji.email}
                        />
                        <FormField
                            // disabled={statusLogowania.status === "loading"}
                            name="message"
                            label="Wiadomość"
                            required
                            type="textarea"
                            rows={4}
                            // error={bledyWalidacji.email}
                        />
                        <Button type="submit">Wyślij</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export interface KontaktViewProps {}

export default KontaktView;
