import useApi, { UseApiOptions } from "./useApi";

import DekanatListItem from "api/schemas/DekanatListItem";
import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";

const useDekanaty = (
    params: DekanatyRequestParams = {},
    options?: UseApiOptions<DekanatyApiResponse>
) => {
    const res = useApi<DekanatyApiResponse>("/dekanaty", {
        params,
        ...options,
    });
    return res;
};

export type DekanatyApiResponse = {
    data: DekanatListItem[];
};

export interface DekanatyRequestParams extends ParafieFiltrParams {
    diecezja?: string; // filtrowanie po diecezji
}

export default useDekanaty;
