import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

// import classNames from "helpers/classNames";
// import styles from "./View.module.scss";

// const cx = classNames(styles);

const View: React.FC<ViewProps> = ({ children }) => {
    const { pathname } = useLocation();

    // scroll window to top when url changes
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [pathname]);

    return <>{children}</>;
};

export interface ViewProps {}

export default View;
