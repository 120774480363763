import { Card, CardBody, Col, Collapse, Row } from "reactstrap";
import React, { useState } from "react";

import ApiDekanatListItem from "api/schemas/DekanatListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ParafiaListItem from "../ParafiaListItem";
import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";
import Preloader from "components/elements/Preloader";
import classNames from "helpers/classNames";
import {
    faChevronCircleRight,
    faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./DekanatListItem.module.scss";
import useCzyPozycjaRozwinieta from "hooks/useCzyPozycjaRozwinieta";
import useParafie from "api/hooks/useParafie";
import Button from "components/elements/Button";
import otworzEksport from "helpers/otworzEksport";

const cx = classNames(styles);

const DekanatListItem: React.FC<DekanatListItemProps> = ({
    dekanat,
    wyszukiwanie,
    filtr,
}) => {
    const [isOpen, setIsOpen] = useCzyPozycjaRozwinieta(wyszukiwanie, filtr);
    const [shouldPrefetch, setShouldPrefetch] = useState(false);
    const parafie = useParafie(
        {
            dekanat: dekanat.guid,
            wyszukiwanie,
            filtr,
        },
        { enabled: dekanat.parafie > 0 && (isOpen || shouldPrefetch) }
    );
    return (
        <Card
            className={cx("card", "bg-transparent")}
            onMouseEnter={() => !shouldPrefetch && setShouldPrefetch(true)}
        >
            <CardBody className={cx("p-0")}>
                <Row
                    form
                    className={cx(
                        "align-items-center",
                        "pl-2",
                        "pl-lg-4",
                        "pr-2",
                        "pr-lg-3",
                        "py-3",
                        { clickable: dekanat.parafie > 0 }
                    )}
                    onClick={() => setIsOpen((open) => !open)}
                >
                    <Col xs="12" sm>
                        {dekanat.parafie > 0 && (
                            <FontAwesomeIcon
                                icon={faChevronCircleRight}
                                className={cx("icon", "mr-2", "mr-lg-3", {
                                    rotate: isOpen,
                                })}
                            />
                        )}
                        Dekanat {dekanat.nazwa}
                    </Col>
                    <Col xs="auto">Parafie: {dekanat.parafie}</Col>
                    <Col xs="auto">
                        <Button
                            outline
                            disabled={dekanat.parafie === 0}
                            type="button"
                            color="green"
                            onClick={() =>
                                otworzEksport(
                                    wyszukiwanie,
                                    filtr,
                                    dekanat.diecezja_guid,
                                    dekanat.guid
                                )
                            }
                            icon={faFileCsv}
                            size="sm"
                        />
                    </Col>
                </Row>
                {dekanat.parafie > 0 && (
                    <Collapse isOpen={isOpen} className={cx("collapse")}>
                        {parafie.status === "loading" && <Preloader />}
                        {parafie.status === "success" &&
                            parafie.data.data.map((parafia, index) => (
                                <ParafiaListItem
                                    key={parafia.guid}
                                    parafia={parafia}
                                />
                            ))}
                    </Collapse>
                )}
            </CardBody>
        </Card>
    );
};

export interface DekanatListItemProps extends ParafieFiltrParams {
    dekanat: ApiDekanatListItem;
}

export default DekanatListItem;
