import {
    Form,
    FormFeedback,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import React, { FormEvent, useEffect } from "react";

import { AktualnoscDetailsApiResponse } from "api/hooks/useAktualnoscDetails";
import ApiError from "api/ApiError";
import Button from "components/elements/Button";
import FormField from "components/elements/FormField";
import pobierzPlikPola from "helpers/pobierzPlikPola";
// import classNames from "helpers/classNames";
import pobierzWartoscPola from "helpers/pobierzWartoscPola";
import { queryCache } from "react-query";
// import styles from "./FormularzDodawaniaAktualnosci.module.scss";
import useApiCallback from "api/hooks/useApiCallback";
import { useHistory } from "react-router-dom";

// const cx = classNames(styles);

const FormularzDodawaniaAktualnosci: React.FC<FormularzDodawaniaAktualnosciProps> = ({
    isOpen,
    onClose,
}) => {
    const { push } = useHistory();
    const [zapisz, metadaneZapisu] = useApiCallback<
        AktualnoscDetailsApiResponse
    >("/aktualnosci", {
        method: "POST",
    });

    const wysylkaFormularza = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        zapisz(
            {
                tytul: pobierzWartoscPola(form, "tytul"),
                zajawka: pobierzWartoscPola(form, "zajawka"),
                tresc: pobierzWartoscPola(form, "tresc"),
                nowy_obrazek: pobierzPlikPola(form, "nowy_obrazek"),
            },
            {
                onSuccess: () => {
                    // wymuszenie przeładowania danych
                    queryCache.invalidateQueries("/aktualnosci");
                },
            }
        );
    };

    // automatyczne przekierowanie na widok szczegółów nowego artykułu
    useEffect(() => {
        if (metadaneZapisu.status !== "success") {
            return;
        }

        if (!metadaneZapisu.data.data) {
            return;
        }

        push(`/aktualnosci/${metadaneZapisu.data.data.guid}`);
    }, [metadaneZapisu.status, metadaneZapisu.data, push]);

    const bledyWalidacji =
        metadaneZapisu.error instanceof ApiError
            ? metadaneZapisu.error.validation || {}
            : {};
    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            toggle={onClose}
            backdrop={metadaneZapisu.status === "loading" ? "static" : true}
        >
            <ModalHeader>Nowy artykuł</ModalHeader>
            <Form onSubmit={wysylkaFormularza}>
                <ModalBody>
                    <FormField
                        required
                        disabled={metadaneZapisu.status === "loading"}
                        name="tytul"
                        label="Tytuł"
                        maxLength={200}
                        error={bledyWalidacji.tytul}
                    />
                    <FormField
                        disabled={metadaneZapisu.status === "loading"}
                        name="nowy_obrazek"
                        label="Obrazek"
                        inlineLabel="Wybierz obrazek"
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        capture="environment"
                        error={bledyWalidacji.nowy_obrazek}
                    />
                    <FormField
                        required
                        disabled={metadaneZapisu.status === "loading"}
                        name="zajawka"
                        label="Zajawka"
                        helpText="Tekst wprowadzający, widoczny na liście aktualności."
                        type="textarea"
                        rows={5}
                        maxLength={500}
                        error={bledyWalidacji.zajawka}
                    />
                    <FormField
                        required
                        disabled={metadaneZapisu.status === "loading"}
                        name="tresc"
                        label="Treść"
                        type="textarea"
                        rows={20}
                        error={bledyWalidacji.tresc}
                    />
                    {!!metadaneZapisu.error && (
                        <FormFeedback valid={false}>
                            {metadaneZapisu.error.message}
                        </FormFeedback>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        outline
                        color="secondary"
                        disabled={metadaneZapisu.status === "loading"}
                        onClick={onClose}
                        label="Anuluj"
                    />
                    <Button
                        color="secondary"
                        type="submit"
                        label="Zapisz"
                        disabled={metadaneZapisu.status === "loading"}
                        loading={metadaneZapisu.status === "loading"}
                    />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export interface FormularzDodawaniaAktualnosciProps {
    isOpen: boolean;
    onClose: () => void | any;
}

export default FormularzDodawaniaAktualnosci;
