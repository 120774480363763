import { Col, Container, Row } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";
import { TypKontaUzytkownika } from "api/schemas/Uzytkownik";
import ViewTitle from "components/elements/ViewTitle";
import classNames from "helpers/classNames";
import { faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import styles from "./ApiView.module.scss";
import useAuth from "hooks/useAuth";

const cx = classNames(styles);

const ApiView: React.FC<ApiViewProps> = () => {
    const { user } = useAuth();
    return (
        <Container className={cx("mt-5")}>
            <Row className={cx("align-items-center")}>
                <Col xs="12" md className={cx("mb-3")}>
                    <ViewTitle>API</ViewTitle>
                </Col>
                {user?.typ_konta === TypKontaUzytkownika.TYP_KONTA_ADMIN && (
                    <Col xs="12" md="auto" className={cx("mb-3")}>
                        <Link
                            className={cx("btn", "btn-secondary")}
                            to="/schematyzm-api/klienci"
                        >
                            <FontAwesomeIcon
                                className={cx("mr-2")}
                                icon={faLaptopCode}
                            />
                            Klienci API
                        </Link>
                    </Col>
                )}
                <Col xs="12">
                    <p>
                        Schematyzm jest otwartą bazą danych wszystkich polskich
                        parafii, dekanatów, diecezji i archidiecezji. Zawsze
                        aktualne dane ze schematyzmu można pobierać za pomocą
                        API, dzięki czemu można korzystać z nich w zewnętrznych
                        systemach. Dane można pobierać z podziałem na diecezje,
                        dekanaty, województwa, czy powiaty – wszystkie dane
                        parafii z określonego regionu, bądź dane pojedynczej
                        parafii. Można też pobierać różnice w zakresie zmian
                        danych – co zmieniło się w określonym czasie, aby móc
                        łatwo aktualizować swoje zasoby.
                    </p>

                    <p>
                        Aby skorzystać z API, skontaktuj się z nami w
                        zakładce&nbsp;"
                        <a href="/kontakt">Kontakt</a>".
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export interface ApiViewProps {}

export default ApiView;
