import useApi, { UseApiOptions } from "./useApi";

import KlientApi from "api/schemas/KlientApi";

const useKlientApiDetails = (
    guid: string,
    options?: UseApiOptions<KlientApiDetailsApiResponse>
) => {
    const res = useApi<KlientApiDetailsApiResponse>(
        `/klienci-api/${guid}`,
        options
    );

    return res;
};

export type KlientApiDetailsParams = {
    strona?: number;
    na_stronie?: number;
};

export type KlientApiDetailsApiResponse = {
    data: KlientApi;
};

export default useKlientApiDetails;
