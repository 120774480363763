import { Col, Container, Row } from "reactstrap";
import Preloader from "components/elements/Preloader";
import React from "react";
import ViewTitle from "components/elements/ViewTitle";
import classNames from "helpers/classNames";
import styles from "./PodziekowaniaView.module.scss";
import useApi from "api/hooks/useApi";

const cx = classNames(styles);

const PodziekowaniaView: React.FC<PodziekowaniaViewProps> = () => {
    const res = useApi<{ data: string[] }>("/podziekowania");
    return (
        <Container className={cx("mt-5")}>
            <Row>
                <Col xs="12">
                    <ViewTitle>Podziękowania</ViewTitle>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    {res.status === "loading" && <Preloader />}
                    {res.status === "error" && <div>Coś poszło nie tak</div>}
                    {res.status === "success" && (
                        <>
                            <p>
                                Szczególne podziękowania za pomoc w
                                przygotowaniu schematyzmu kierujemy do:
                            </p>
                            <ul className={cx("pl-3", "mt-2")}>
                                {res.data.data.map((osoba, index) => (
                                    <li key={index} className={cx("mb-3")}>
                                        {osoba}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export interface PodziekowaniaViewProps {}

export default PodziekowaniaView;
