import {
    CustomInputProps,
    FormFeedback,
    FormGroup,
    InputProps,
    Label,
} from "reactstrap";
import React, { ChangeEvent, useMemo, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputComponent from "./components/InputComponent";
import classNames from "helpers/classNames";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "./FormField.module.scss";

const cx = classNames(styles);

const FormField: React.FC<FormFieldProps> = ({
    type,
    className,
    label,
    inlineLabel,
    required,
    helpText,
    error,
    onClear,
    onChange,
    ...props
}) => {
    const input = useRef<HTMLInputElement>(null);
    const instance = useMemo(() => `form-input-${Math.random()}`, []);
    const handleClear = () => {
        const elem = input.current;
        if (!elem) {
            return;
        }

        const prevValue = elem.value;
        elem.value = "";
        elem.focus();
        if (onClear && !!prevValue) {
            onClear();
        }
    };
    return (
        <FormGroup className={cx(className, "group")}>
            {!!label && (
                <Label for={instance}>
                    {label}
                    {!!required && (
                        <span className={cx("text-danger", "pl-1")}>*</span>
                    )}
                </Label>
            )}
            {!!helpText && (
                <p className={cx("text-muted", "font-italic")}>{helpText}</p>
            )}
            <InputComponent
                {...props}
                type={type}
                onChange={onChange}
                innerRef={input}
                id={instance}
                required={required}
                invalid={!!error}
                label={
                    type === "switch" || type === "radio" || type === "file"
                        ? inlineLabel
                        : undefined
                }
            />
            {!!error && (
                <FormFeedback valid={false} style={{ display: "block" }}>
                    {error}
                </FormFeedback>
            )}
            {!!onClear && (
                <FontAwesomeIcon
                    onClick={handleClear}
                    icon={faTimes}
                    className={cx("clear")}
                />
            )}
        </FormGroup>
    );
};

export interface FormFieldProps extends Omit<InputProps, "type"> {
    className?: string;
    type?: InputProps["type"] | CustomInputProps["type"];
    label?: string;
    inlineLabel?: string;
    helpText?: string;
    error?: string | null | undefined;
    onClear?: () => void | any;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void | any;
}

export default FormField;
