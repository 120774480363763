import { Col, Row } from "reactstrap";
import React from "react";
import classNames from "helpers/classNames";
import styles from "./CtaSection.module.scss";

const cx = classNames(styles);

const CtaSection: React.FC<CtaSectionProps> = () => {
    return (
        <Row className={cx("container")} noGutters>
            <Col xs="12" md="6" className={cx("bg-primary", "section")}>
                SCHEMATYZM
            </Col>
            <Col xs="12" md="6" className={cx("bg-secondary", "section")}>
                Diecezje, dekanaty, parafie
                <br />
                Kościoła katolickiego w Polsce
            </Col>
        </Row>
    );
};

export interface CtaSectionProps {}

export default CtaSection;
