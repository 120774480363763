import { Col, Container, Row } from "reactstrap";
import React, { useState } from "react";

import Button from "components/elements/Button";
import FormularzDodawaniaAktualnosci from "./components/FormularzDodawaniaAktualnosci";
import NewsCard from "../StronaGlowna/components/News/Components/NewsCard";
import Pagination from "components/elements/Pagination";
import Preloader from "components/elements/Preloader";
import { TypKontaUzytkownika } from "api/schemas/Uzytkownik";
import ViewTitle from "components/elements/ViewTitle";
import classNames from "helpers/classNames";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "./AktualnosciView.module.scss";
import useAktualnosci from "api/hooks/useAktualnosci";
import useAuth from "hooks/useAuth";
import useQueryString from "hooks/useQueryString";

const cx = classNames(styles);

const AktualnosciView: React.FC<AktualnosciViewProps> = () => {
    const { user } = useAuth();
    const [pokazFormularzDodawania, setPokazFormularzDodawania] = useState(!1);
    const [{ strona, ...query }, updateQuery] = useQueryString<{
        strona: string;
    }>();

    const aktualnosci = useAktualnosci({
        strona: parseInt(strona || "1", 10) || 1, // zabezpieczenie przed stroną "0",
        na_stronie: 12,
    });

    const mozeDodawac = user?.typ_konta === TypKontaUzytkownika.TYP_KONTA_ADMIN;
    return (
        <Container className={cx("mt-5")}>
            <Row className={cx("align-items-center")}>
                <Col xs="12" md className={cx("mb-3")}>
                    <ViewTitle>Aktualności</ViewTitle>
                </Col>
                {!!mozeDodawac && (
                    <Col xs="12" md="auto" className={cx("mb-3")}>
                        <Button
                            color="secondary"
                            className={cx("w-100")}
                            onClick={() => setPokazFormularzDodawania(true)}
                            label="Nowy artykuł"
                            icon={faPlus}
                        />
                    </Col>
                )}
            </Row>
            {aktualnosci.status === "loading" && <Preloader />}
            {aktualnosci.status === "success" && (
                <>
                    <Row className={cx("align-items-stretch")}>
                        {aktualnosci.data.data.map((artykul) => (
                            <Col
                                xs="12"
                                sm="6"
                                md="4"
                                key={artykul.guid}
                                className={cx("mb-3")}
                            >
                                <div
                                    className={cx(
                                        "rounded",
                                        "border",
                                        "shadow",
                                        "h-100"
                                    )}
                                >
                                    <NewsCard
                                        title={artykul.tytul}
                                        date={artykul.czas_utworzenia}
                                        image={artykul.obrazek}
                                        link={`/aktualnosci/${artykul.guid}`}
                                    >
                                        {artykul.zajawka || ""}
                                    </NewsCard>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Pagination
                        className={cx("mt-3")}
                        page={aktualnosci.data.meta.current_page}
                        totalPages={aktualnosci.data.meta.last_page}
                        onChange={(nowaStrona) => {
                            updateQuery({
                                ...query,
                                strona: nowaStrona.toString(),
                            });
                            window.scrollTo(0, 0);
                        }}
                    />
                </>
            )}
            {mozeDodawac && (
                <FormularzDodawaniaAktualnosci
                    isOpen={pokazFormularzDodawania}
                    onClose={() => setPokazFormularzDodawania(false)}
                />
            )}
        </Container>
    );
};

export interface AktualnosciViewProps {}

export default AktualnosciView;
