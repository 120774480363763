const pobierzWartoscPola = (form: HTMLFormElement, pole: string) => {
    const pola = form.elements;
    const input = pola.namedItem(pole) as HTMLInputElement;

    if (input.type === "checkbox") {
        return input.checked ? "1" : "0";
    }

    return input.value || "";
};

export default pobierzWartoscPola;
