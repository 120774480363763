import { QueryOptions, useMutation } from "react-query";
import executeRequest, {
    IRequestParams,
    RequestMethod,
} from "api/executeRequest";

import useAuth from "hooks/useAuth";

function useApiCallback<ResponseType>(
    url: string,
    options?: UseApiCallbackOptions<ResponseType>
) {
    const { csrf_token } = useAuth();
    const { method = "POST", csrf_token: optionCsrfToken } = options || {};
    const res = useMutation<ResponseType, IRequestParams>(
        (params: IRequestParams) =>
            executeRequest(
                url,
                params || {},
                method,
                csrf_token || optionCsrfToken || undefined
            )
    );
    return res;
}

export interface UseApiCallbackOptions<ResponseType = {}>
    extends QueryOptions<ResponseType> {
    method?: RequestMethod;
    csrf_token?: string;
}

export default useApiCallback;
