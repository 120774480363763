import { TypKontaUzytkownika } from "api/schemas/Uzytkownik";
import useAuth from "hooks/useAuth";
import { useMemo } from "react";

const useCzyMozeEdytowacDiecezje = (diecezjaId: number | null) => {
    const { user } = useAuth();

    const czyMoze = useMemo(() => {
        if (!user || !diecezjaId) {
            return false;
        }

        if (user.typ_konta === TypKontaUzytkownika.TYP_KONTA_ADMIN) {
            return true;
        }

        if (
            user.typ_konta === TypKontaUzytkownika.TYP_KONTA_DIECEZJA &&
            user.diecezja_id === diecezjaId
        ) {
            return true;
        }

        return false;
    }, [user, diecezjaId]);

    return czyMoze;
};

export default useCzyMozeEdytowacDiecezje;
