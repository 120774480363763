import React from "react";
import { Col, Container, Row } from "reactstrap";
import ViewTitle from "components/elements/ViewTitle";
import classNames from "helpers/classNames";
import styles from "./OSchematyzmieView.module.scss";

const cx = classNames(styles);

const OSchematyzmieView: React.FC<OSchematyzmieViewProps> = () => {
    return (
        <Container className={cx("mt-5")}>
            <Row className={cx("schematyzmInfo")}>
                <Col xs="12">
                    <ViewTitle>O schematyzmie</ViewTitle>
                </Col>
                <Col xs="12">
                    <h2 className={cx("h6", "font-weight-bold", "mt-3")}>
                        Przyjęte nazewnictwo
                    </h2>
                </Col>
                <Col xs="12">
                    <p>
                        Schematyzm to wykaz (lista) diecezji Kościoła
                        katolickiego w Polsce z podziałem na dekanaty i
                        przynależne do nich parafie wraz z adresami
                        korespondencyjnymi.
                    </p>

                    <p>
                        Nazwy diecezji i dekanatów przyjęto z oficjalnych stron
                        www poszczególnych diecezji.
                    </p>
                    <br />
                    <p>Dla parafii przyjęto następujące nazewnictwo:</p>
                    <ol>
                        <li>
                            <p>
                                <strong>Nazwa parafii</strong> w pełnym
                                brzmieniu, składająca się z ciągu znaków
                                „Parafia Rzymskokatolicka” oraz części będącej
                                najczęściej wezwaniem kościoła parafialnego np.
                                „Świętej Anny”.
                            </p>
                            <p>
                                W nazwie parafii nie stosuje się skrótów (np.
                                św., BM, NSPJ, MB, NMP).
                            </p>
                            <p>
                                W nazwie parafii nie dodaje się nazwy
                                miejscowości.
                            </p>
                            <p>
                                Przykładową nazwą parafii jest: Parafia
                                Rzymskokatolicka Świętej Anny.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ze względów praktycznych dodano{" "}
                                <strong>Nazwę skróconą parafii</strong>, która
                                składa się z ciągu znaków „Parafia” oraz części
                                najczęściej będącej wezwaniem kościoła
                                parafialnego np. „św. Anny”.
                            </p>
                            <p>
                                Przykładową nazwą skróconą parafii jest: Parafia
                                św. Anny.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ze względu na lokalną tradycję dodano trzecią
                                nazwę parafii, zwyczajową.{" "}
                                <strong>Nazwą zwyczajową</strong> może być nazwa
                                miejscowości lub dzielnicy a w niektórych
                                przypadkach tytuł kościoła np. Fara,
                                Sanktuarium, Katedra.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ze względów historycznych dodaną czwartą nazwę:{" "}
                                <strong>Nazwę historyczną</strong>. Ma ona taki
                                zapis, jaki istnieje w dokumencie erygującym
                                parafię, w pisowni oryginalnej dla ówczesnego
                                języka polskiego.
                            </p>
                        </li>
                    </ol>
                </Col>
                <Col xs="12">
                    <h2 className={cx("h6", "font-weight-bold", "mt-3")}>
                        Adres parafii
                    </h2>
                </Col>
                <Col xs="12">
                    <p>
                        Przyjęto, że adresem parafii jest adres korespondencyjny
                        miejsca, w którym przechowywane są księgi parafialne i w
                        którym można załatwiać sprawy urzędowe czyli adres
                        kancelarii parafialnej (adres urzędu parafialnego).
                    </p>
                    <p>
                        W większości przypadków adres kancelarii pokrywa się z
                        adresem plebanii (miejsca zamieszkania proboszcza).
                    </p>
                    <p>Adresem parafii nie jest adres kościoła parafialnego.</p>
                    <br />
                    <p>
                        Adres parafii zawiera 4 pola: Nazwę ulicy, numer
                        posesji, kod pocztowy i nazwę miejscowości.
                    </p>
                    <ol>
                        <li>
                            <p>
                                <strong>Nazwę ulicy</strong> przyjęto zgodnie z
                                zapisem w Rejestrze Głównego Urzędu
                                Statystycznego TERYT (
                                <a
                                    href="http://eteryt.stat.gov.pl/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    http://eteryt.stat.gov.pl
                                </a>
                                ).
                            </p>
                            <p>
                                W nazwie ulicy nie stosuje się skrótów: ul.,
                                pl., al. ani podobnych.
                            </p>
                            <p>
                                W różnych miejscowościach zarejestrowane są
                                nazwy o różnym brzmieniu np. Tadeusza
                                Kościuszki, T.Kościuszki, Kościuszki.
                            </p>
                            <p>
                                Niektóre ulice nie mają swojej nazwy, głównie w
                                mniejszych miejscowościach. W takim przypadku
                                pole z nazwą ulicy pozostaje puste a w adresie
                                korespondencyjnym w to miejsce podaje się nazwę
                                miejscowości.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Numer posesji</strong> (nieruchomości)
                                jest zgodny z Geoportalem Otwartych Danych
                                Przestrzennych (
                                <a
                                    href="https://polska.e-mapa.net/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    https://polska.e-mapa.net
                                </a>
                                ). Ma postać liczby (np. 12), liczby z literą
                                (np. 12a lub 12A), czasami inną np. 12/1 lub
                                12-1.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Kod pocztowy</strong> - formacie XX-XXX
                                zgodny z danymi ze strony Poczty Polskiej (
                                <a
                                    href="https://kody.poczta-polska.pl/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    https://kody.poczta-polska.pl
                                </a>
                                ) i stosowanymi zarówno w Rejestrze TERYT jak i
                                w Geoportalu.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Nazwa miejscowości</strong> z pisownią
                                zgodną z Rozporządzeniem Ministra Cyfryzacji z
                                2012 r. (
                                <a
                                    href="https://isap.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20130000200"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    https://isap.sejm.gov.pl
                                </a>
                                ) ujednolicającą nazewnictwo miast, wsi, kolonii
                                czy zdrojów obowiązujące w Polsce.
                            </p>
                        </li>
                    </ol>
                    <p>
                        Uwaga:
                        <br />W adresie korespondencyjnym nie podaje się nazwy
                        miejscowości poczty. Taki wymóg istniał do roku 2012 –
                        patrz Rozporządzenie powyżej.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export interface OSchematyzmieViewProps {}

export default OSchematyzmieView;
