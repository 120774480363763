import React from "react";
import { Col, Container, Row } from "reactstrap";
import ViewTitle from "components/elements/ViewTitle";
import classNames from "helpers/classNames";
import styles from "./RegulaminView.module.scss";

const cx = classNames(styles);

const RegulaminView: React.FC<RegulaminViewProps> = () => {
    return (
        <Container className={cx("mt-5")}>
            <Row>
                <Col xs="12">
                    <ViewTitle>Regulamin</ViewTitle>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <h6>Strona w przygotowaniu</h6>
                </Col>
            </Row>
        </Container>
    );
};

export interface RegulaminViewProps {}

export default RegulaminView;
