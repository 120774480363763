import React from "react";
// import classNames from "helpers/classNames";
import _trim from "lodash/trim";
// import styles from "./MultilineText.module.scss";

// const cx = classNames(styles);

const MultilineText: React.FC<MultilineTextProps> = ({ text }) => {
    if (!text) {
        return <></>;
    }
    return (
        <>
            {text
                .split(/\r?\n/) // rozbicie po znakach nowej linii
                .map((line) => _trim(line)) // usuniecie brzegowych białych znaków
                .map((akapit, i) => (
                    <p key={i}>{akapit || <>&nbsp;</>}</p>
                ))}
        </>
    );
};

export interface MultilineTextProps {
    text: string;
}

export default MultilineText;
