import { useHistory, useLocation } from "react-router-dom";

import qs from "qs";
import { useCallback } from "react";

type UpdateQuery<QueryParams> = (newQuery: QueryParams) => void;

function useQueryString<QueryParams>(): [
    QueryParams,
    UpdateQuery<QueryParams>
] {
    const { search } = useLocation();
    const { push } = useHistory();

    const query = (qs.parse(search, {
        ignoreQueryPrefix: true,
    }) as unknown) as QueryParams;

    const update = useCallback(
        (newQuery: QueryParams) => {
            const newSearch = qs.stringify(newQuery, {
                arrayFormat: "brackets",
            });
            push({ search: newSearch });
        },
        [push]
    );

    return [query, update];
}

export default useQueryString;
