import { Card, CardBody, Col, Collapse, Row } from "reactstrap";
import React, { useState } from "react";

import ApiDiecezjaListItem from "api/schemas/DiecezjaListItem";
import DekanatListItem from "../DekanatListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";
import Preloader from "components/elements/Preloader";
import classNames from "helpers/classNames";
import {
    faChevronCircleRight,
    faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./DiecezjaListItem.module.scss";
import useCzyPozycjaRozwinieta from "hooks/useCzyPozycjaRozwinieta";
import useDekanaty from "api/hooks/useDekanaty";
import Button from "components/elements/Button";
import otworzEksport from "helpers/otworzEksport";

const cx = classNames(styles);

const DiecezjaListItem: React.FC<DiecezjaListItemProps> = ({
    diecezja,
    wyszukiwanie,
    filtr,
}) => {
    const [isOpen, setIsOpen] = useCzyPozycjaRozwinieta(wyszukiwanie, filtr);
    const [shouldPrefetch, setShouldPrefetch] = useState(false);
    const dekanaty = useDekanaty(
        {
            diecezja: diecezja.guid,
            wyszukiwanie,
            filtr,
        },
        { enabled: diecezja.dekanaty > 0 && (isOpen || shouldPrefetch) }
    );
    return (
        <Card
            className={cx("card", "bg-transparent")}
            onMouseEnter={() => !shouldPrefetch && setShouldPrefetch(true)}
        >
            <CardBody className={cx("p-0")}>
                <Row
                    form
                    className={cx(
                        "align-items-center",
                        "px-2",
                        "px-md-3",
                        "py-3",
                        {
                            clickable: diecezja.dekanaty > 0,
                        }
                    )}
                    onClick={() => setIsOpen((open) => !open)}
                >
                    <Col xs="12" sm>
                        {diecezja.dekanaty > 0 && (
                            <FontAwesomeIcon
                                icon={faChevronCircleRight}
                                className={cx("icon", "mr-2", "mr-md-3", {
                                    rotate: isOpen,
                                })}
                            />
                        )}
                        {diecezja.nazwa}
                    </Col>
                    <Col xs="auto">Dekanaty: {diecezja.dekanaty}</Col>
                    <Col xs="auto">&#9679;</Col>
                    <Col xs="auto">Parafie: {diecezja.parafie}</Col>
                    <Col xs="auto">
                        <Button
                            disabled={diecezja.parafie === 0}
                            type="button"
                            color="green"
                            onClick={() =>
                                otworzEksport(
                                    wyszukiwanie,
                                    filtr,
                                    diecezja.guid
                                )
                            }
                            icon={faFileCsv}
                            size="sm"
                        />
                    </Col>
                </Row>
                {diecezja.dekanaty > 0 && (
                    <Collapse isOpen={isOpen} className={cx("collapse")}>
                        {dekanaty.status === "loading" && <Preloader />}
                        {dekanaty.status === "success" &&
                            dekanaty.data.data.map((dekanat, index) => (
                                <DekanatListItem
                                    key={dekanat.guid}
                                    dekanat={dekanat}
                                    wyszukiwanie={wyszukiwanie}
                                    filtr={filtr}
                                />
                            ))}
                    </Collapse>
                )}
            </CardBody>
        </Card>
    );
};

export interface DiecezjaListItemProps extends ParafieFiltrParams {
    diecezja: ApiDiecezjaListItem;
}

export default DiecezjaListItem;
