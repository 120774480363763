import useApi, { UseApiOptions } from "./useApi";

import Aktualnosc from "api/schemas/Aktualnosc";

const useAktualnoscDetails = (
    guid: string,
    options?: UseApiOptions<AktualnoscDetailsApiResponse>
) => {
    const res = useApi<AktualnoscDetailsApiResponse>(
        `/aktualnosci/${guid}`,
        options
    );

    return res;
};

export type AktualnoscDetailsParams = {
    strona?: number;
    na_stronie?: number;
};

export type AktualnoscDetailsApiResponse = {
    data: Aktualnosc;
};

export default useAktualnoscDetails;
