const pobierzPlikPola = (form: HTMLFormElement, pole: string): File | null => {
    const pola = form.elements;
    const input = pola.namedItem(pole) as HTMLInputElement;

    if (input.type !== "file" || !input.files) {
        return null;
    }

    return input.files[0] || null;
};

export default pobierzPlikPola;
