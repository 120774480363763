import { API_KEY } from "./constants";
import ApiError from "api/ApiError";
import _isEmpty from "lodash/isEmpty";
import _trimEnd from "lodash/trimEnd";
import getApiUrl from "api/getApiUrl";
import qs from "qs";

const executeRequest = function <ResponseType = {}>(
    url: string,
    params: IRequestParams = {},
    method: RequestMethod = "GET",
    csrfToken?: string
): QueryPromise<ResponseType> {
    // prepare api url
    let fullUrl = getApiUrl(url);
    // Create a new AbortController instance for this request
    const controller = new AbortController();
    // Get the abortController's signal
    const abortSignal = controller.signal;

    const fetchSettings: RequestInit = {
        // pass the method to the request
        method: method,
        // Pass the signal to your request
        signal: abortSignal,
        // include cookies in the request
        credentials: "include",
        // request headers
        headers: {
            Accept: "application/json",
            "API-Key": API_KEY,
        },
    };

    if (csrfToken) {
        fetchSettings.headers = {
            ...fetchSettings.headers,
            "X-CSRF-TOKEN": csrfToken,
        };
    }

    if (method === "GET") {
        if (!_isEmpty(params)) {
            const query = qs.stringify(params, {
                arrayFormat: "brackets",
            });
            fullUrl = `${fullUrl}?${query}`;
        }
    } else {
        fullUrl = `${_trimEnd(fullUrl, "/")}`; // remove / at the end of url

        // sprawdzenie czy treść zapytania zawiera plik
        const hasFile: boolean = Object.values(params).reduce((prev, value) => {
            if (prev) {
                return prev;
            }

            return value instanceof File || value instanceof Blob;
        }, false);

        if (!hasFile) {
            // jeśli brak pliku, przygotuj zwykłe zapytanie JSON
            fetchSettings.headers = {
                ...fetchSettings.headers,
                "Content-Type": "application/json",
            };
            fetchSettings.body = JSON.stringify(params || {});
        } else {
            // jeśli jest plik, przygotuj zapytanie POST multipart/form-data z wykorzystaniem
            // method spoofingu https://laravel.com/docs/master/routing#form-method-spoofing
            fetchSettings.method = "POST";
            if (method !== "POST") {
                params["_method"] = method.toUpperCase();
            }

            const formData = new FormData();
            for (const key in params) {
                const value = params[key];
                if (typeof value === "undefined") {
                    continue;
                }
                if (Array.isArray(value)) {
                    for (const item of value) {
                        formData.append(`${key}[]`, item);
                    }
                    continue;
                }
                if (value === null) {
                    formData.append(key, "");
                    continue;
                }
                if (typeof value === "boolean") {
                    formData.append(key, value ? "1" : "0");
                    continue;
                }
                if (value instanceof File || value instanceof Blob) {
                    formData.append(key, value);
                    continue;
                }

                formData.append(key, value);
            }

            fetchSettings.body = formData;
        }
    }

    const promise = ((async () => {
        const res = await fetch(fullUrl, fetchSettings);
        if (!res.ok) {
            // kod http błędu
            const status = res.status;

            // czytanie odpowiedzi z API
            const resPromise = res.json() as Promise<{
                message?: string;
                errors?: { [key: string]: string[] };
            }>;
            const resData = await resPromise;

            // treść wiadomości o błędzie przychodząca z API
            const message = resData ? resData.message || "" : "";

            // błędy walidacji
            const errors = resData.errors || {};
            const validatonErrors: { [key: string]: string } = {};
            for (const field in errors || {}) {
                validatonErrors[field] = errors[field][0];
            }

            throw new ApiError(status, message, validatonErrors);
        }

        const contentType = res.headers.get("content-type");
        if (contentType === "application/json") {
            const resPromise = res.json() as Promise<ResponseType>;
            const resData = await resPromise;
            return resData;
        }

        const resText = res.text();
        return resText;
    })() as unknown) as QueryPromise<ResponseType>;

    promise.cancel = () => controller.abort();

    return promise;
};

export type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";
export interface IRequestParams {
    [key: string]: any;
}
export interface QueryPromise<T> extends Promise<T> {
    cancel: Function;
}

export default executeRequest;
