import useApi, { UseApiOptions } from "./useApi";

import ParafiaListItem from "api/schemas/ParafiaListItem";
import ParafieFiltrParams from "api/schemas/ParafieFiltrParams";

const useParafie = (
    params: ParafieRequestParams = {},
    options?: UseApiOptions<ParafieApiResponse>
) => {
    const res = useApi<ParafieApiResponse>("/parafie", { params, ...options });
    return res;
};

export type ParafieApiResponse = {
    data: ParafiaListItem[];
};

export interface ParafieRequestParams extends ParafieFiltrParams {
    dekanat?: string; // filtrowanie po dekanacie
}

export default useParafie;
